import DefaultLayout from "../layouts/default.layout";
import ContactsTableCard from "../components/cards/contactsTable.card";
import LoggedIn from "../layouts/loggedIn.layout";
import {UserContext} from "../contexts/user.context";
import {useSearchParams, useParams} from "react-router-dom";

import {useContext} from "react";



const Contacts = () => {
  const {user} = useContext(UserContext)
  let {customerId} = useParams();
  customerId = parseInt(customerId);
  return (

    <LoggedIn>
      <DefaultLayout title={user?.role === "codi admin" && user?.customer_id === customerId ? "Manage Codi Admin": "Manage Users"}>
        <div className='manage-users-container'>
        <ContactsTableCard  customerId={customerId}  />
        </div>
      </DefaultLayout>
    </LoggedIn>
  );
};

export default Contacts;
