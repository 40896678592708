import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerContext } from '../../contexts/customer.context';
import { UserContext } from '../../contexts/user.context';

const CustomerInfo = () => {
    const navigate = useNavigate();
    const info = useContext(CustomerContext);
    const detail = useContext(UserContext);

    console.log('Info', info, detail);


    return (
        <>
            <div className="border-2 border-solid p-4 rounded-2xl flex flex-col gap-5">
                <div className="text-lg font-bold">
                    Customer information
                </div>
                <div className="flex flex-col">
                    <div className="text-md font-bold">Name:</div>
                    <div className="text-md">{info.customer?.fullname}</div>
                </div>
                <div className="flex flex-col">
                    <div className="text-md font-bold">Email:</div>
                    <div className="text-md">{info.customer?.email}</div>
                </div>
                <div className="flex flex-col">
                    <div className="text-md font-bold">Organization:</div>
                    <div className="text-md">{info.customer?.business_name}</div>
                </div>
                <div className="flex flex-row">
                    <div className="w-max border-2 border-solid border-red-600 rounded-xl py-2 px-4 text-red-600 font-bold hover:bg-red-600 hover:text-white cursor-pointer"
                        onClick={() => {
                            navigate('/account/password')
                        }}>
                        Change Password
                    </div>
                </div>
            </div>

        </>
    );
}

export default CustomerInfo;