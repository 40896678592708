import LoggedIn from "../layouts/loggedIn.layout";
import DefaultLayout from "../layouts/default.layout";
import CardTemplate from "../components/cards/template/card.template.component";
import CreateShippingLabelButton from "../components/buttons/createShippingLabel.button.component";
import ShippingLabelsTableCard from "../components/cards/shippingLabelsTable.card";
import { useState } from "react";

const ShippingLabels = () => {
  const [newShippingLabel, setNewShippingLabel] = useState(null);
  return (
    <LoggedIn>
      <DefaultLayout title="Shipping Labels">
        <CardTemplate>
          <div className="flex justify-center">
            <CreateShippingLabelButton
              newShippingLabelState={[newShippingLabel, setNewShippingLabel]}
            />
          </div>
        </CardTemplate>
        <ShippingLabelsTableCard
          newShippingLabelState={[newShippingLabel, setNewShippingLabel]}
        />
      </DefaultLayout>
    </LoggedIn>
  );
};

export default ShippingLabels;
