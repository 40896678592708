import Table from "./table.component";
import TableMessage from "./tableMessage.component";
import ViewPrintLabelButton from "../buttons/viewPrintLabelButton.component";

const SHIPPING_LABELS_HEADERS = [
  {
    header: "Tracking #",
    dataField: "tracking_number",
    style: "font-interSemiBold text-codiDarkGray text-base",
  },
  {
    header: "Created on",
    dataField: "created_at",
    style: "font-interSemiBold text-codiDarkGray text-base",
  },
  {
    header: "Details",
    dataField: null,
    style: "",
  },
];

const ShippingLabelsTable = ({ shippingLabels = [] }) => {
  console.log({ shippingLabels });
  if (shippingLabels?.length === 0) {
    return <TableMessage>No Shipping Labels Found</TableMessage>;
  }
  return (
    <Table headers={SHIPPING_LABELS_HEADERS}>
      {shippingLabels &&
        shippingLabels.map((shippingLabel, index1) => (
          <tr key={`${shippingLabel?._id}-${index1}`} className="h-16">
            {SHIPPING_LABELS_HEADERS.map(({ dataField, style }, index2) => {
              if (index2 === SHIPPING_LABELS_HEADERS.length - 1) {
                return (
                  <td className="flex items-center justify-center h-16">
                    <ViewPrintLabelButton _id={shippingLabel?._id} />
                  </td>
                );
              }
              return (
                <td
                  key={`${shippingLabel?._id}-${index1}-${index2}`}
                  className={`${style} text-center w-[${
                    100 / SHIPPING_LABELS_HEADERS.length
                  }]`}
                >
                  {shippingLabel[dataField]}
                </td>
              );
            })}
          </tr>
        ))}
    </Table>
  );
};

export default ShippingLabelsTable;
