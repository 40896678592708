const LogoutButton = ({ onLogout = () => null }) => {
  const logout = onLogout();
  return (
    <button
      onClick={logout}
      className="text-codiRed font-interExtraBold text-base mx-2"
    >
      (Logout)
    </button>
  );
};

export default LogoutButton;
