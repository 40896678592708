import { useSearchParams } from "react-router-dom";
import ContactSaveForm from "../forms/contactAdd.form";
import Heading from "../shared/heading.landing";

export function ContactSaveContent (){
    const [searchParams] = useSearchParams();
    const  customerId  = searchParams.get('customerId');

    return <>
        <Heading text={'Manage Contact'} />
        <div className=" flex flex-col gap-3">
            <ContactSaveForm customerId={customerId} />
        </div>
    </>
}