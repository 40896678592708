import Footer1 from "../components/footer.component";
import LoginForm1 from "../components/forms/login.form1";
import Header1 from "../components/header.component1";

const Login1 = () => {
    return (
        <>
            <div className="bg-white flex flex-col items-center min-h-screen">
                <div className="md:w-3/4 w-full">
                    <Header1 showMyAccount />
                    {/* <content > */}
                        <LoginForm1 />
                    {/* </content > */}
                    <Footer1 />
                </div>
            </div>
        </>
    );
}

export default Login1;