import { gql } from "@apollo/client";

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation UpdateCustomer($id: Int!
          $firstname: String
          $lastname: String
          $email: String
          $mobile: String
          $address: String
          $phone: String
          $city: String
          $state: String
          $business_name: String 
          $zip: String) {
              updateCustomer(id: $id 
                  firstname: $firstname
                  lastname: $lastname
                  email: $email
                  mobile: $mobile
                  address: $address
                  phone: $phone
                  city: $city
                  state: $state
                  business_name: $business_name
                  zip: $zip) {
                    id
                  }
              }
`;

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation createCustomer(
          $firstname: String
          $lastname: String
          $email: String
          $mobile: String
          $address: String
          $phone: String
          $city: String
          $state: String
          $business_name: String 
          $zip: String) {
              createCustomer(
                  firstname: $firstname
                  lastname: $lastname
                  email: $email
                  mobile: $mobile
                  address: $address
                  phone: $phone
                  city: $city
                  state: $state
                  business_name: $business_name
              zip: $zip) {
          id
    }
  }
`;

