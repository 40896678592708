import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import { CustomerContext } from '../../contexts/customer.context';

export default function Sidebar({ show, setter }) {
    const location = useLocation();
    const currentPath = location.pathname;
    const { user } = useContext(UserContext);


    const className = "w-[250px] transition-[margin-left] ease-in-out duration-300 fixed md:static top-0 bottom-0 left-0 z-40";
    // Append class based on state of sidebar visiblity
    const appendClass = show ? " ml-0" : " ml-[-250px] md:ml-0";

    // Clickable menu items
    const MenuItem = ({ name, route }) => {
        // Highlight menu item based on currently displayed route
        // const colorClass = router.pathname === route ? "text-white" : "text-white/50 hover:text-white";

        return (
            <div className={`p-2`}>
                <div className={`hover:bg-evstarLighGreen border-solid rounded-xl hover:text-white ${currentPath.includes(route) ? 'bg-evstarLighGreen text-white' : ''}`}>
                    <Link
                        to={route}
                        onClick={() => {
                            console.log('Route:', route);
                            setter(oldVal => !oldVal);
                        }}
                        className={`flex gap-1 text-md pl-6 py-3 border-b-[1px] border-b-evstarLighGreen/10`}
                    >
                        <div className='w-full'>{name}</div>
                    </Link>
                </div>
            </div>
        )
    }

    const getCustomerId = (customer) => {
        if(typeof customer !== "object" ) return 0;
        else return customer?.id;
    }

    const {customer} = useContext(CustomerContext);


    // Overlay to prevent clicks in background, also serves as our close button
    const ModalOverlay = () => (
        <div
            className={`flex md:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30`}
            onClick={() => {
                setter(oldVal => !oldVal);
            }}
        />
    );

    return (
        <>
            <div className={`${className}${appendClass} text-black bg-gray-50`}>
                <div className="flex flex-col">
                    {
                        user.role === 'admin'?
                        <MenuItem
                            name="Submit Claims"
                            route="/submit-claim"
                        />:<></>
                    }
                    <MenuItem
                        name="View Claims"
                        route="/view-claims"
                    />
                    <MenuItem
                        name="View Covered Devices"
                        route="/covered-devices"
                    />
                    <MenuItem
                        name="Account Info"
                        route="/account"
                    />
                    { user.role==='admin' && <MenuItem
                        name="Manage Users"
                        route={`/manage-contacts/${getCustomerId(customer)}`}
                    />}

                    {
                        user.role==='admin'?
                        <MenuItem
                        name="Customers"
                        route="/customers"
                    /> : <></>}
                </div>
            </div>
            {show ? <ModalOverlay /> : <></>}
        </>
    )

    
   
}