import CardTemplate from "./template/card.template.component";
import AssetsTable from "../tables/assets.table.component";
import PaginationBar from "../paginationBar.component";
import SearchOptions from "../searchOptions.component";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/user.context";
import { useSearchParams } from "react-router-dom";
import useAsset from "../../hooks/useAsset";

const DEFAULT_SEARCH_OPTIONS = {
  search: "",
  sortBy: "number",
  sortDirection: "asc",
  statusFilter: [],
  page: 1,
  maxPages: 10,
};

const AssetsTableCard = ({ title = "" }) => {
  const { user } = useContext(UserContext);
  const { getAssets, getAssetCount } = useAsset();
  const [searchParams] = useSearchParams();
  const [assets, setAssets] = useState([]);
  const [assetTotal, setAssetTotal] = useState(0);
  const [searchOptions, setSearchOptions] = useState(DEFAULT_SEARCH_OPTIONS);
  const { search, sortBy, sortDirection, statusFilter, maxPages } =
    searchOptions;
  const [page, setPage] = useState(+searchParams?.get("page") || 1);

  useEffect(() => {
    if (!user?.id) return;
    if (!searchParams.toString()) return;
    const fetchAndSetAssets = async () => {
      if (searchOptions.search !== "") {
        searchParams.append("page", "1");
      }

      const AssetInput = {
        warranty: true,
        //customer_id: user?.customer_id,
      };
      const fetchedAssets = await getAssets({
        searchParams: searchParams.toString(),
      }, AssetInput);
      setAssets(fetchedAssets);
    };
    fetchAndSetAssets();
    const fetchAssetCount = async () => {
      const fetchedAssetCount = await getAssetCount({
        searchParams: searchParams.toString(),
      });
      setAssetTotal(fetchedAssetCount);
    };
    fetchAssetCount();
  }, [searchParams, user]);

  return (
    <CardTemplate title={title}>
      <SearchOptions
        type="assets"
        searchOptionsState={[searchOptions, setSearchOptions]}
      />
      <AssetsTable assets={assets} user={user}/>
      <PaginationBar currentPage={page} maxPages={Math.ceil(assetTotal/25)} />
    </CardTemplate>
  );
};

export default AssetsTableCard;
