import useLogout from "../hooks/useLogout";
import LogoutButton from "./buttons/logout.button.component";

const Header = ({
  businessName = "School/District Name",
  username = "Username",
}) => {
  const handleLogout = useLogout;
  return (
    <header className="flex justify-end text-base flex-row font-interExtraBold py-3 bg-black text-white">
      <div className="uppercase">
        {businessName ? `${businessName} |` : ""} {username}
      </div>
      <LogoutButton onLogout={handleLogout} />
    </header>
  );
};

export default Header;
