import { ReactComponent as PlusIcon } from "../../assets/icons/plus.icon.svg";
import { Link } from "react-router-dom";

const SubmitClaimNavButton = () => {
  return (
    <Link
      to="/create-claim"
      className="flex flex-row uppercase justify-center items-center bg-black text-white rounded-large px-5 py-3 text-base"
    >
      <PlusIcon className="w-5 h-5 fill-white" />
      Submit Claim
    </Link>
  );
};

export default SubmitClaimNavButton;
