const SelectionInput = ({ label, children, ...otherProps }) => {
  return (


    <div className="flex flex-col gap-1 flex-1">
      <label className="font-inter text-base">{label}</label>
      <select
        {...otherProps}
        className="bg-lightGray h-full rounded-lg p-2 w-full text-base font-interMedium"
      >
        {children}
      </select>
    </div>
  );
};

export default SelectionInput;
