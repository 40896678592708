import { useEffect, useState,useContext } from "react";
import { useSearchParams } from "react-router-dom";
import SelectionInput from "../selection.input.component";
import { UserContext } from "../../../contexts/user.context";




const SortBySelectionInput = ({ options, onChange, ...otherProps }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSort = searchParams.get("sort") || (options === "contacts" ? "email" : "created_at");
  const isDescending = currentSort.startsWith("-");
  const [sortBy, setSortBy] = useState(currentSort.replace("-", ""));

  const { user } = useContext(UserContext);

const OPTIONS_MAP = {
  claims: [
    { value: "number", name: "Ticket #" },
    { value: "asset_serial", name: "Serial #" },
    { value: "status", name: "Status" },
    { value: "created_at", name: "Created" },
    { value: "updated_at", name: "Last Modified" },
    { value: "received_at", name: "Received" },
    { value: "problem_type", name: "Issue Type" },
    { value: "customer_business_then_name", name: "Customer" },
  ],
  assets: [
    { value: "asset_serial", name: "Serial #" },
    { value: "make", name: "Make" },
    { value: "model", name: "Model" },
    { value: "name", name: user?.role!=="codi admin"?"Device Detail":"Customer" },
  ],
  shippingLabels: [
    { value: "tracking_number", name: "Tracking #" },
    { value: "created_at", name: "Created" },
  ],
  contacts: [
    {name: "Email", value: "email"},
    {name: "name", value: "name"},
  ],
  customers: [
    {value:"business_name", name:"Customer"}
  ]
};

  useEffect(() => {
    const newSortParam = isDescending ? `-${sortBy}` : sortBy;
    searchParams.set("sort", newSortParam);
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, setSearchParams]);

  const handleChange = (e) => {
    setSortBy(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <SelectionInput label="Sort By" onChange={handleChange} {...otherProps}>
      {OPTIONS_MAP[options]?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </SelectionInput>
  );
};

export default SortBySelectionInput;
