import CardTemplate from "./template/card.template.component";
import AssetsTable from "../tables/assets.table.component";

const AssociatedDevicesCard = ({ assets = [] }) => {
  return (
    <CardTemplate title="Associated Devices">
      <AssetsTable assets={assets} />
    </CardTemplate>
  );
};

export default AssociatedDevicesCard;
