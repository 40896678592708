export const mappings ={
    'Assigned Service Network Provider': {
      '182228': 'Electrify EVSE',
      '182229': 'BGIS',
      '182513': 'Test EV SP'
    },
    'Are Parts Needed': {
      '182612': 'Yes',
      '182613': 'No',
      '184395': 'Unknown'
    },
    'Dispatch Status': {
      '183792': 'Open',
      '183793': 'Closed',
      '183794': 'Cancelled'
    },
    'New Part 1 Status': {
      '183830':'Part Ordered',
      '183831':'Part Installed',
      '183832':'Part Not Used',
      '183833':'Part Not Required'
    },
    'New Part 2 Status': {
      '183834':'Part Ordered',
      '183835':'Part Installed',
      '183836':'Part Not Used',
      '183837':'Part Not Required'
    },
    'New Part 3 Status': {
      '183838':'Part Ordered',
      '183839':'Part Installed',
      '183840':'Part Not Used',
      '183841':'Part Not Required'
    },
    'New Part 4 Status': {
      '183842':'Part Ordered',
      '183843':'Part Installed',
      '183844':'Part Not Used',
      '183845':'Part Not Required'
    },
    'Technician 1 Type': {
        '183814':'Electrician',
        '183815':'Other'
    },
    'Dispatch Sub Status': {
       '183795':'Open',
       '183796':'Acknowledge',
       '183797':'Assigned',
       '183798':'Scheduled',
       '183799':'Rescheduled',
       '183800':'En Route',
       '183801':'Arrived',
       '183802':'Repair In Progress',
       '183803':'Parts Ordered',
       '183804':'Repair Complete',
       '183805':'Cancelled'
    },
    'Dispatch Disposition': {
            '183806':'Repair Unsuccessful',
            '183807':'Repair Successful',
            '183808':'Unrepairable',
            '183809':'Repair Not Required',
            '183810':'Repair Declined',
            '183811':'Cancelled'
    },
    'Site Contact Confirmation': {
      '183812': 'Yes',
      '183813': 'No'
    },
    'Have the Parts been Ordered': {
      '182614': 'Yes',
      '182615': 'No'
    },
    'Services Required':{
            '183816':'Board Replacement',
            '183817':'Cable Management',
            '183818':'Charger Replacement',
            '183819':'Commission',
            '183820':'Configuration',
            '183821':'Cord Replacement',
            '183822':'Firmware Update',
            '183823':'Incoming Power',
            '183824':'Lockbox',
            '183825':'Screen Replacement',
            '183826':'SIM Card Swap',
            '183827':'Troubleshoot',
            '183828':'Total Removal',
            '183829':'Other'
    },
    'Parts Received ':{
      '184214':'Yes',
      '184215':'No'
    },
  };