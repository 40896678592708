import { ReactComponent as RightArrowIcon } from "../../assets/icons/longRightArrow.icon.svg";
import { useNavigate } from "react-router-dom";

const ViewDetailsButton = ({ id = "" }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/assets/${id}`);
  };
  return (
    <button
      onClick={handleClick}
      className="items-center font-interMedium text-sm gap-1 flex flex-row rounded-xtraLarge px-4 py-2 text-codiBlue bg-cyan-100"
    >
      View Details <RightArrowIcon className="fill-codiBlue" />
    </button>
  );
};

export default ViewDetailsButton;
