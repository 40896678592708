const SimpleCardTemplate = ({
  style = "",
  label = "",
  value = 0,
  children,
}) => {
  const destructuredLabel = label?.split(" ");
  return (
    <div
      className={`${style} rounded-xtraLarge px-6 py-4 flex flex-row justify-center items-center gap-4`}
    >
      {children}
      <div className="text-white flex flex-col items-center">
        {value}
        <label className="font-interBold whitespace-pre text-lg">
          {destructuredLabel.length > 0 &&
            destructuredLabel.map((word) => (
              <span key={word}>
                {word}
                <br />
              </span>
            ))}
        </label>
      </div>
    </div>
  );
};

export default SimpleCardTemplate;
