const Table = ({ headers, children }) => {
  return (
    <table className="table-auto w-full">
      <thead>
        <tr className="border-b-2 border-black">
          {headers.map(({ header }) => (
            <th
              key={header}
              className={`w-[${
                100 / headers.length
              }] uppercase text-codiTableHeaders font-interSemiBold font-sm`}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default Table;
