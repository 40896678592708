import { ReactComponent as DocumentIcon } from "../../assets/icons/document.icon.svg";
import SimpleCardTemplate from "./template/simpleCard.template.component";

const ClosedClaimsCard = ({ closedClaims = 0 }) => {
  return (
    <SimpleCardTemplate
      style="bg-codiBlue"
      label="Closed Claims"
      value={closedClaims}
    >
      <DocumentIcon className="fill-white" />
    </SimpleCardTemplate>
  );
};

export default ClosedClaimsCard;
