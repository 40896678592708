import Table from "./table.component";
import TableMessage from "./tableMessage.component";
import { useNavigate } from "react-router-dom";
//customer_business_then_name
const CLAIMS_HEADERS = [
  {
    header: "Ticket #",
    dataField: "number",
    style: "font-interSemiBold text-codiDarkGray text-base",
  },
  {
    header: "Serial #",
    dataField: "asset_serial",
    style: "font-interSemiBold text-codiDarkGray text-base",
  },
  {
    header: "Status",
    dataField: "status",
    style: "font-interSemiBold text-codiRed text-base",
  },
  {
    header: "Created",
    dataField: "created_at",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  {
    header: "Customer",
    dataField: "customer_business_then_name",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  {
    header: "Received",
    dataField: "received_at",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  {
    header: "Last Modified",
    dataField: "updated_at",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  {
    header: "Issue Type",
    dataField: "problem_type",
    style: "font-interSemiBold text-codiRed text-base",
  },
];

const ClaimsTable = ({ claims = [] }) => {
  const navigate = useNavigate();
  if (claims.length === 0) {
    return <TableMessage>No Claims Found</TableMessage>;
  }

  const handleClick = (_id) => {
    navigate(`/claims/${_id}`);
  };

  return (
      window.screen.width <= 678 ?
      <div className='grid-container'>
        {claims.length > 0 &&
            claims.map((claim, index1) => (
                <div   onClick={() => handleClick(claim?._id)}
                      key={`${claim?._id}-${index1}`}
                      className=" hover:bg-lightGray hover:cursor-pointer grid">
                  {CLAIMS_HEADERS.map(({ header, dataField, style }, index2) => (
                      <div
                          key={`${claim?._id}-${index1}-${index2}`}
                          className={`${style} text-center w-[${
                              100 / CLAIMS_HEADERS.length
                          }]`}
                      >
                        {header}: {claim[dataField]}
                      </div>
                  ))}
                </div>
            ))}
      </div> :
    <Table headers={CLAIMS_HEADERS}>
      {claims.length > 0 &&
        claims.map((claim, index1) => (
          <tr
            onClick={() => handleClick(claim?._id)}
            key={`${claim?._id}-${index1}`}
            className="h-10 hover:bg-lightGray hover:cursor-pointer"
          >
            {CLAIMS_HEADERS.map(({ dataField, style }, index2) => (
              <td
                key={`${claim?._id}-${index1}-${index2}`}
                className={`${style} text-center w-[${
                  100 / CLAIMS_HEADERS.length
                }]`}
              >
                {claim[dataField]}
              </td>
            ))}
          </tr>
        ))}
    </Table>
  );
};

export default ClaimsTable;
