import React, { useState } from "react";
import ContactUsButton from "../buttons/contactUs.button.component";

const DEFAULT_SUPPORT_FORM = {
  name: "",
  email: "",
  comments: "",
};

const SupportForm = () => {
  const [supportForm, setSupportForm] = useState(DEFAULT_SUPPORT_FORM);
  const { name, email, comments } = supportForm;

  const handleChange = (e) => {
    setSupportForm({ ...supportForm, [e.target.name]: e.target.value });
  };

  return (
    <form className="flex flex-col gap-4">
      <div className="flex flex-col">
        <label className="font-interSemiBold" htmlFor="name">
          Name:
        </label>
        <input
          className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="flex flex-col">
        <label className="font-interSemiBold" htmlFor="email">
          Email Address:
        </label>
        <input
          className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="flex flex-col">
        <label className="font-interSemiBold" htmlFor="comments">
          Comments/Details:
        </label>
        <textarea
          className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
          id="comments"
          name="comments"
          value={comments}
          onChange={handleChange}
          rows={5}
          required
        />
      </div>
      <div className="flex justify-center">
        <ContactUsButton supportFormState={[supportForm, setSupportForm]} />
      </div>
    </form>
  );
};

export default SupportForm;
