import Heading from "../shared/heading.landing";

export function ManageUsersContent({ contacts = [], customerId }) {
    const cellClass = `w-[35%] md:w-[20%] overflow-x-clip content-center`;

    return <>
        <Heading text={'Users'} />
        <div className="w-full overflow-x-auto">
            <div className="flex flex-row font-bold p-2 w-[175%] md:w-full gap-1">
                <div className={cellClass}>Email</div>
                <div className={cellClass}>Name</div>
                <div className={cellClass}>City</div>
                <div className={cellClass}>Role</div>
                <div className={cellClass}>Actions</div>
            </div>
            <div><hr className={`border-[1px] border-black rounded-3xl w-[175%] md:w-full`} /></div>
            {
                contacts.map((c) => {
                    // console.log('Contact: ', c);
                    return <>
                        <div key={`${c._id}`} className="flex flex-row font-bold p-2 w-[175%] md:w-full gap-1">
                            <div className={cellClass}>{c.email}</div>
                            <div className={cellClass}>{c.name}</div>
                            <div className={cellClass}>{c.city}</div>
                            <div className={cellClass}>{c.role}</div>
                            <div className={cellClass}>{ }</div>
                        </div>
                    </>
                })
            }
        </div>
    </>
}