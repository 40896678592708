import { useMutation } from "@apollo/client";
import { CREATE_SHIPPING_LABEL_MUTATION } from "../../api/mutations/shipping-label.mutations";

const CreateShippingLabelButton = ({ newShippingLabelState }) => {
  const [newShippingLabel, setNewShippingLabel] = newShippingLabelState || [
    null,
    () => {},
  ];
  const [createShippingLabel] = useMutation(CREATE_SHIPPING_LABEL_MUTATION);
  const handleClick = async () => {
    try {
      const response = await createShippingLabel();
      if (response?.error) throw new Error(response.error);
      const shippingLabel = response?.data?.createShippingLabel;
      setNewShippingLabel(shippingLabel);
      alert(`Shipping label: ${shippingLabel?.tracking_number} created!`);
    } catch (error) {
      alert(error?.message);
    }
  };
  return (
    <button
      className="uppercase border font-interSemiBold border-codiRed hover:bg-white bg-codiRed text-white hover:text-codiRed py-2 px-8 rounded-lg"
      onClick={handleClick}
    >
      Create New Label
    </button>
  );
};

export default CreateShippingLabelButton;
