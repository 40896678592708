import { useNavigate } from "react-router-dom";
import OutLinedButton from "../buttons/outlined.button.component";

const WhatsCoveredContent = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className=" p-12 min-h-[80vh] ">
                <div className="text-xl pb-8">
                    What's Covered?
                </div>
                <div>
                    <ul className="list-disc ml-8 ">
                        <li>Your make and model of EV charger</li>
                        <li>Accidental Damage from Handling (ADH):
                            <ul style={{ listStyleType: 'circle' }} className="ml-8">
                                <li>Drops</li>
                                <li>Spills</li>
                                <li>Cracks</li>
                            </ul>
                        </li>
                        <li>Mechanical and Electrical Failure after OEM (Original Equipment Manufacturer) warranty expires</li>
                        <li>Power surge</li>
                    </ul>
                </div>
                <div className="w-max pt-8">
                    <OutLinedButton text={'Back to home'} onClick={() => {
                        navigate(-1);
                    }} />
                </div>
            </div>
        </>
    );
}
export default WhatsCoveredContent;