import DefaultLayout from "../layouts/default.layout";
import ClaimsTableCard from "../components/cards/claimsTable.card";
import LoggedIn from "../layouts/loggedIn.layout";

const Claims = () => {
  return (
    <LoggedIn>
      <DefaultLayout title="Track Claims">
        <ClaimsTableCard />
      </DefaultLayout>
    </LoggedIn>
  );
};

export default Claims;
