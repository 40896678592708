import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { PASSWORD_RESET_MUTATION } from "../../api/mutations/contact.mutations"
import { UserContext } from "../../contexts/user.context";


const UpdatePasswordForm = () => {
    const [passwordForm, setPasswordForm] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
    const { currentPassword, newPassword, confirmPassword } = passwordForm;

    const handleChange = (e) => {
        setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
    };

    const token = localStorage.getItem("token");

    const user = useContext(UserContext).user;

    //console.log(user, token);

    const [PasswordReset] = useMutation(PASSWORD_RESET_MUTATION);

    const handleSubmit = () => {

        if (newPassword.length < 8) {
            alert("Password should contain atleast 8 characters");
            return;
        }

        if (/\s/.test(newPassword)) {
            alert("Space literals are not allowed in password!");
            return;
        }

        if (!/\d/.test(newPassword)) {
            alert("Password should contain numbers");
            return;
        }

        if (!/[A-Za-z]/.test(newPassword)) {
            alert("Password should contain alphabets");
            return;
        }

        if (confirmPassword === newPassword) {
            try {
                //PasswordReset(newPassword, token, currentPassword, user.email);
                PasswordReset({
                    variables: {
                        password: newPassword,
                        token: token,
                        currentPassword: currentPassword,
                        email: user.email
                    }, onCompleted: (res) => {
                        alert("Password changed successfully!");
                        console.log(res);
                        setPasswordForm({
                            currentPassword: "",
                            newPassword: "",
                            confirmPassword: ""
                        });
                    }, onError(error) {
                        error?.message ? alert(error.message) : alert("Contact system admin.");
                    }
                });
            } catch (error) {
                console.log(error);
                error?.message ? alert(error.message) : alert("Contact system admin.");
            }
        } else {
            alert("Your password mis match on confirm")
        }
    }

    return (
        <form className="flex flex-col gap-4">
            <div className="flex flex-col">
                <label className="font-interSemiBold" htmlFor="currentPassword">
                    Current Password:
                </label>
                <input
                    className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
                    type="password"
                    id="currentPassword"
                    name="currentPassword"
                    onChange={handleChange}

                    required
                />
            </div>
            <div className="flex flex-col">
                <label className="font-interSemiBold" htmlFor="newPassword">
                    New Password:
                </label>
                <input
                    className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="flex flex-col">
                <label className="font-interSemiBold" htmlFor="confirmPassword">
                    Confirm New Password:
                </label>
                <input
                    className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="flex justify-center">
                <button
                    className="flex justify-center text-center font-bold bg-black items-center border-[2px] border-gray-500 px-4 py-2  rounded-2xl"
                    style={{ color: "white" }} type={"button"} onClick={handleSubmit}>Update Password
                </button>
            </div>
        </form>
    );
};

export default UpdatePasswordForm;
