import PageTitle from "../components/pageTitle.component";

const DefaultLayout = ({ title, children }) => {
  return (
    <div className="flex flex-col w-full p-1 gap-1">
      <PageTitle title={title} />
      {children}
    </div>
  );
};

export default DefaultLayout;
