import { gql } from "@apollo/client";

export const GET_TICKET_QUERY = gql`
  query GetTicket($_id: String, $id: Int) {
    ticket(_id: $_id, id: $id) {
      _id
      id
      number
      number_string
      subject
      created_at
      customer_business_then_name
      updated_at
      received_at
      resolved_at
      problem_type
      submitted_by
      status_changes {
        date
        old_status
        new_status
      }
      status
      comments {
        updated_at
        body
        subject
        hidden
        id
        user_id
        created_at
        tech
      }
      properties
      assets {
        _id
        id
        name
        asset_serial
        asset_type
        make
        model
        warranty
        warranty_type
        warranty_start_date
        warranty_end_date
        properties
      }
      contact_name
    }
  }
`;

export const GET_TICKETS_QUERY = gql`
  query GetTickets($searchParams: String, $TicketInput: TicketInput, $user_id: Int) {
    tickets(searchParams: $searchParams, TicketInput: $TicketInput, user_id: $user_id ) {
      _id
      number
      number_string
      customer_id
      subject
      created_at
      customer_business_then_name
      updated_at
      received_at
      resolved_at
      problem_type
      status
      asset_serial
      comments {
        updated_at
        body
        hidden
        id
      }
      properties
      assets {
        asset_serial
      }
      contact_name
      contact {
        name
      }
    }
  }
`;

export const GET_TICKET_COUNT_QUERY = gql`
  query GetTicketCount($searchParams: String, $TicketInput: TicketInput) {
    getTicketCount(searchParams: $searchParams, TicketInput: $TicketInput)
  }
`;
