// @/components/Layout/MenuBarMobile.js
import React from 'react'
import { ReactComponent as SideBarArrow } from "../../assets/icons/sidebar.arrow.svg";


export default function MenuBarMobile({ setter }) {
    return (
        <nav className="md:hidden z-20 static top-0 left-0  bg-zinc-100  px-2 flex flex-row items-center">
            <div className='text-evstarLighGreen'>
                <button
                    className="text-sm flex text-white"
                    onClick={() => {
                        setter(oldVal => !oldVal);
                    }}
                >
                    <SideBarArrow className='w-4 ' />
                </button>
            </div>
        </nav>
    )
}