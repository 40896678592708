const Footer1 = () => {
    return (
    <>
        <footer>
            <div className="bg-evstarGreyish flex flex-row items-center justify-between py-3 w-full px-4">
                <img  
                    src="https://claims.goevstar.com/Content/images/evstar-logo-white-small.png"
                    className="h-9 w-24 text-sm"
                />
                <div>
                    <span className="text-white text-sm">
                        EVSTAR &copy; 2023
                    </span>
                </div>
            </div>
        </footer>
    </>
    );
}

export default Footer1;