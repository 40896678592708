import SimpleCardTemplate from "./template/simpleCard.template.component";
import { ReactComponent as DocumentIcon } from "../../assets/icons/document.icon.svg";

const OpenClaimsCard = ({ openClaims = 0 }) => {
  return (
    <SimpleCardTemplate
      style="bg-codiGreen"
      label="Open Claims"
      value={openClaims}
    >
      <DocumentIcon className="fill-white" />
    </SimpleCardTemplate>
  );
};

export default OpenClaimsCard;
