import { useState } from "react";
import { ManageUsersContent } from "../components/content/manage.users.content";
import Header1 from "../components/header.component1";
import MenuBarMobile from "../components/sidebar/menubar.mobile";
import Sidebar from "../components/sidebar/sidebar.component";
import LoggedIn1 from "../layouts/loggedIn.layout1";
import { ContactSaveContent } from "../components/content/contact.save.content";

export function ContactSave1 (){
    const [showSidebar, setShowSidebar] = useState(false);

    return <>
    <LoggedIn1>
    <div className="bg-white flex flex-col items-center min-h-screen">
                <div className="lg:w-5/6 w-full">
                    <Header1 showWhatsCovered />
                    
                    <div className="h-full ">
                        <div className="flex h-full ">
                            <MenuBarMobile setter={setShowSidebar} />
                            <Sidebar show={showSidebar} setter={setShowSidebar} />
                            <div className="flex flex-col w-full min-h-screen p-4 gap-4">
                                <div className="flex flex-col w-full min-h-screen p-4 gap-4">
                                    <ContactSaveContent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </LoggedIn1>
    </>
}