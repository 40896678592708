import SimpleCardTemplate from "./template/simpleCard.template.component";
import { ReactComponent as DocumentIcon } from "../../assets/icons/document.icon.svg";

const TotalClaimsCard = ({ totalClaims = 0 }) => {
  return (
    <SimpleCardTemplate
      style="bg-codiDarkGray"
      label="Total Claims"
      value={totalClaims}
    >
      <DocumentIcon className="fill-white" />
    </SimpleCardTemplate>
  );
};

export default TotalClaimsCard;
