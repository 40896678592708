import { useEffect, useState } from "react";
import Footer1 from "../components/footer.component";
import Header1 from "../components/header.component1";
import SendPasswordResetEmailButton from "../components/buttons/sendPasswordResetEmail.button.component";
import OutLinedButton from "../components/buttons/outlined.button.component";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";

const SendPasswordReset1 = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (e) => {
        setEmail(e.target.value);
    };
    const toggleLoading = () => {
        setIsLoading(prev => {
            return !prev;
        });
    }

    useEffect(() => {
        console.log('isLoading', isLoading);
    }, [isLoading])

    return (
        <>
            <div className="bg-white flex flex-col items-center min-h-screen">
                <div className="md:w-5/6 w-full">
                    <Header1 showMyAccount />
                    <div className="min-h-[80vh] flex justify-center sm:py-6">
                        <div className="sm:px-8 py-10 sm:py-0 flex flex-col gap-6 w-4/5 sm:w-2/5 sm:justify-center sm:bg-evstarLighGreen/5 rounded-xl">
                            <div className="font-bold text-xl text-center">Reset Your Password</div>
                            <div className="text-center text-evstarGreyish">Please enter the email address on your account below. We will email you a link to reset your password</div>
                            <div >
                                <input
                                    className=" border border-codiTableHeaders rounded text-base font-interMedium forgot-email w-full"
                                    type="email"
                                    autoComplete="on"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-row justify-between">
                                <div>
                                    <OutLinedButton text={'Back'} onClick={handleBackClick} />
                                </div>
                                <div>
                                    {isLoading ?
                                        <div className="px-6">
                                            <Spinner color="light-green" className="h-8 w-8" />
                                        </div> :
                                        <SendPasswordResetEmailButton emailState={[email, setEmail]} toggleLoading={toggleLoading} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer1 />
                </div>
            </div>
        </>
    );
}
export default SendPasswordReset1;