import CardTemplate from "./template/card.template.component";
import ClaimDetail from "../claimDetail.component";

const DETAIL_LABELS = [
  {
    label: "Claim Id:",
    value: "number",
    style: "text-lg font-interMedium text-base",
  },
  {
    label: "Claim Status:",
    value: "status",
    style: "text-lg font-interMedium text-base text-codiRed",
  },
  {
    label: "Repair Type:",
    value: "problem_type",
    style: "text-lg font-interMedium text-base text-codiRed",
  },
  {
    label: "Created On:",
    value: "created_at",
    style: "text-lg font-interMedium text-base",
  },
  {
    label: "Received On:",
    value: "received_at",
    style: "text-lg font-interMedium text-base",
  },
  {
    label: "Last Modified:",
    value: "updated_at",
    style: "text-lg font-interMedium text-base",
  },
];

const TEST_TICKET = {
  _id: "",
  number: "",
  status: "",
  problem_type: "",
  created_at: "",
  received_at: "",
  updated_at: "",
};

const ClaimDetailsCard = ({ ticket = TEST_TICKET }) => {
  return (
    <CardTemplate title="Claim Details">
      <div className="flex flex-row justify-evenly container-header">
        {DETAIL_LABELS.map(({ label, style, value }, index) => (
          <ClaimDetail label={label} key={`${value}-${index}`}>
            <p className={`${style}`}>{ticket[value]}</p>
          </ClaimDetail>
        ))}
      </div>
    </CardTemplate>
  );
};

export default ClaimDetailsCard;
