const ClaimDetail = ({ label, children }) => {
  return (
    <div className="container-detail">
      <label className="text-black font-interSemiBold text-lg uppercase">
        {label}
      </label>
      {children}
    </div>
  );
};

export default ClaimDetail;
