import CardTemplate from "./template/card.template.component";
import ContactsTable from "../tables/contacts.table.component";
import PaginationBar from "../paginationBar.component";
import SearchOptions from "../searchOptions.component";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/user.context";
import { CustomerContext } from "../../contexts/customer.context";
import { useSearchParams } from "react-router-dom";
import useContact from "../../hooks/useContact";
import { useNavigate } from "react-router-dom";
import {FETCH_CONTACTS_COUNT_QUERY} from "../../api/queries/contact.queries";
import {useQuery} from "@apollo/client";

const DEFAULT_SEARCH_OPTIONS = {
  search: "",
  sortBy: "number",
  sortDirection: "asc",
  statusFilter: [],
  page: 1,
  maxPages: 10,
};

const ContactsTableCard = ({ title = "", customerId }) => {
  const { user } = useContext(UserContext);
  const [totalRecords, setTotalRecords] = useState();
  const { customer } = useContext(CustomerContext);
  const { fetchContacts, fetchContactCount } = useContact();
  const {FetchContactsCount} = useQuery(FETCH_CONTACTS_COUNT_QUERY);
  const [searchParams] = useSearchParams();
  const [contacts, setContacts] = useState([]);
  const [searchOptions, setSearchOptions] = useState(DEFAULT_SEARCH_OPTIONS);
  const { search, sortBy, sortDirection, statusFilter, maxPages } =
    searchOptions;
  const [page, setPage] = useState(+searchParams?.get("page") || 1);

  const navigate = useNavigate();
  const customer_id = customer.id;
  const searchVars = searchParams.toString();

  let reload = false;
  let totalPages = 1;

  useEffect(() => {
    if (!user?.id) return;
    if (!searchParams.toString()) return;

    const fetchAndSetContacts = async () => {
      const fetchedContacts = await fetchContacts({
        customer_id: parseInt(customerId),
        searchParams: searchParams.toString(),
      });
      setContacts(fetchedContacts);
      //console.log(fetchedContacts);
    };

    const fetchContactsTotal = async () => {
      const fetchContactsNumber = await fetchContactCount({
        customer_id: parseInt(customerId),
        searchParams: searchParams.toString(),
      });
      setTotalRecords(fetchContactsNumber);
      console.log(fetchContactsNumber);
    };

    fetchAndSetContacts();
    fetchContactsTotal();
  }, [searchParams, user, reload]);



  const navigateToContactSave = () => {
    navigate(`/contact-save?customerId=${customerId}`);
  };

  const state = {
    reload: false
  };



  return (
    <CardTemplate title={title}>
      <div className='new-user-btn-container'>
      <button onClick={navigateToContactSave} className='uppercase border font-interSemiBold border-evstarLighGreen hover:bg-white bg-evstarLighGreen text-white hover:text-evstarLighGreen py-2 px-8 rounded-lg transition duration-300' >{user?.role === "codi admin" && customerId === user?.customer_id? "Add New Codi Admin" : "Add New User" }</button>
      </div>
      <SearchOptions
        type="contacts"
        searchOptionsState={[searchOptions, setSearchOptions]}
      />
      <ContactsTable contacts={contacts} customerId={customerId}  />
      {<PaginationBar currentPage={page} maxPages={Math.ceil(totalRecords/10)} />}
    </CardTemplate>
  );
};

export default ContactsTableCard;
