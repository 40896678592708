import CardTemplate from "./template/card.template.component";
import ClaimForm from "../forms/claim.form";

const CreateClaimFormCard = () => {
  return (
    <CardTemplate>
      <ClaimForm />
    </CardTemplate>
  );
};

export default CreateClaimFormCard;
