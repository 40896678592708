import { useMutation } from "@apollo/client";
import { SEND_EMAIL } from "../../api/mutations/email.mutations";

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const ContactUsButton = ({ supportFormState }) => {
  const [supportForm, setSupportForm] = supportFormState;
  const { name, email, comments } = supportForm;
  const [sendEmail] = useMutation(SEND_EMAIL);
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      if (!name || !email || !comments)
        return alert("Please fill out all fields.");
      if (!emailPattern?.test(email))
        return alert("Please enter a valid email.");
      const response = await sendEmail({
        variables: {
          from: "codi",
          template: "codiSupportEmail",
          subject: `New Support Claims from ${name}`,
          message: comments,
          emails: [email],
        },
      });
      if (response?.error) throw new Error(response.error);
      alert("Email sent to support!");
      setSupportForm({ name: "", email: "", comments: "" });
    } catch (error) {
      error?.message ? alert(error.message) : alert("Contact system admin.");
    }
  };
  return (
    <button
      className="uppercase border font-interSemiBold border-codiRed bg-white hover:bg-codiRed hover:text-white text-codiRed py-2 px-8 rounded-lg"
      onClick={handleClick}
    >
      Contact Us
    </button>
  );
};

export default ContactUsButton;
