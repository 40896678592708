import { useEffect, useState } from "react";
import useTicket from "../../hooks/useTicket";

const AddCommentForm = ({ ticket_id, newCommentState }) => {
  const [newComment, setNewComment] = newCommentState || [{}, () => null];
  const { addComment } = useTicket();
  const [feedback, setFeedback] = useState("");

  const handleChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (!feedback) return alert("Please enter feedback.");
    const res = await addComment({
      ticket_id,
      newStatus: "Customer Reply",
      body: feedback,
      subject: "Customer Reply",
      tech: "customer-reply",
    });
    if (res?.error) return alert("Error sending feedback.");
    setFeedback("");
    setNewComment(res);
  };
  return (
    <form className="flex flex-col w-full gap-4">
      <textarea
        onChange={handleChange}
        value={feedback}
        name="feedback"
        className="overflow-hidden p-4 rounded-xtraLarge h-[100px] border-[1px] border-codiTableHeaders"
      />
      <div className="flex justify-start items-center">
        <button
          onClick={handleClick}
          className="hover:bg-codiRed hover:text-white flex justify-center text-center items-center text-codiRed border-[1px] border-codiRed px-4 py-2 rounded-lg"
        >
          Add Feedback
        </button>
      </div>
    </form>
  );
};

export default AddCommentForm;
