import LoggedOutLayout from "../layouts/loggedOut.layout";
import LoginLayout from "../layouts/login.layout";
import section from "../layouts/section.layout";
import LoginForm from "../components/forms/login.form";
import emblemImage from "../assets/images/codi-emblem.png";
import { useNavigate } from "react-router-dom";

import CardTemplate from "../components/cards/template/card.template.component";

const Login = () => {
  const navigate = useNavigate();
  const navigateToReset = () => {
    navigate("/send-password-reset");
  };
  const navigateToSupport = () => {
    navigate("/support");
  };
  const navigateToTermsAndCondition = () => {
    navigate("/programterms-consumerelectronics");
  };
  return (
    <LoginLayout>
      <div className="w-full p-6 gap-8 wrapper">
        <div className="rounded-xtraLarge p-4 bg-white w-full">
          <div className="items-center content-center text-center ">
            <img
              className="slide-image"
              src={emblemImage}
              alt="CODi Emblem"
              style={{ width: 200 }}
            />
          </div>
          <LoginForm />

        </div>
        <div className="grid grid-cols-3">
          
            <span
                className=" hover:cursor-pointer hover:underline fs-14px font-bold"
                onClick={navigateToReset}
            >
              Forgot Password
            </span>
          
            <span
              className=" hover:cursor-pointer hover:underline text-center fs-14px font-bold"
              onClick={navigateToTermsAndCondition}
              
              
            
            >
              Terms & Conditions
            </span>
          
            <span
              className=" hover:cursor-pointer hover:underline text-right fs-14px font-bold"
              onClick={navigateToSupport}
            >
              Contact Support
            </span>
          
        </div>
        
        
        
      </div>
    </LoginLayout>
  );
};

export default Login;
