import { useContext, useEffect, useRef, useState } from "react";
import { CustomerContext } from "../../contexts/customer.context";
import { GET_CLIENT_CUSTOMER_QUERY } from "../../services/api/queries/customer.queries";
import FilledButton from "../buttons/filled.button.button.component";
import Heading from "../shared/heading.landing";
import { useLazyQuery } from "@apollo/client";
import useCustomers from "../../hooks/useCustomers";

const CustomersContent = () => {
    const cellClass = `min-w-[35%] sm:min-w-[25%]`;

    const { customer } = useContext(CustomerContext);
    const [clientCustomers, setClientCustomers] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [clientCustomerID, setClientCustomerID] = useState(0);
    const [selectedClientCustomer, setSelectedClientCustomer] = useState(undefined);


    const { fetchClientCustomers } = useCustomers();

    useEffect(() => {
        if (!customer?.id) return;

        const fetchAndSetClientCustomers = async () => {
            const fetchedClientCustomers = await fetchClientCustomers({ id: customer.id });
            console.log('Fetched Client & Customers: ', fetchedClientCustomers);
            setClientCustomers(fetchedClientCustomers);
        }

        fetchAndSetClientCustomers();
    }, [customer])

    useEffect(() => {
        const contactIndex = clientCustomers.findIndex((c) => c.id == clientCustomerID);
        if (contactIndex !== -1) {
            const contactsList = clientCustomers[contactIndex].contacts;
            setContacts(contactsList);
            setSelectedClientCustomer(clientCustomers[contactIndex]);
        }
        else {
            setContacts([]);
            setSelectedClientCustomer(undefined);
        }
    }, [clientCustomerID]);


    return (
        <>
            <Heading text={'Customers'} />
            <div className="border-2 border-solid p-4 rounded-2xl flex flex-col gap-3">
                <div className="flex flex-row gap-1 items-center">
                    <select name="problem" id="" className="w-1/2 md:w-2/4 rounded-lg" onChange={(e) => setClientCustomerID(e.target.value)}>
                        <option value="" aria-readonly>Select Customer</option>

                        {clientCustomers && clientCustomers.length > 0 && clientCustomers.map((v, i) => <option key={v.id} value={v.id}>{v.fullname}</option>)}
                    </select>
                    {/* <div className="w-max">
                        <div className="cursor-pointer bg-evstarLighGreen px-4 py-1.5 rounded-lg text-white text-sm" onClick={() => {
                            let id = customer?.id;
                            Customer(id);
                        }}>
                            View Customer
                        </div>
                    </div> */}
                </div>

            </div>
            <div className="flex flex-col border-2 border-solid p-4 rounded-2xl">
                <div className="font-bold text-lg">Search</div>
                <input type="text" className="h-8 w-full md:w-1/2 bg-evstarLandingInputField rounded-lg" />
            </div>
            <div className="w-full overflow-x-auto">
                <div className="flex flex-row justify-between font-bold ">
                    <div className={cellClass}>Contact Name</div>
                    <div className={cellClass}>Email</div>
                    <div className={cellClass}>Phone Number</div>
                    <div className={cellClass}>Customer</div>
                </div>
                <div><hr className={`border-[1px] border-black rounded-3xl w-full`} /></div>
                {
                    contacts.map((c) => {
                        return <div key={c.id} className="flex flex-row justify-between">
                            <div className={cellClass}>{c.name}</div>
                            <div className={cellClass}>{c.email}</div>
                            <div className={cellClass}>{c.phone}</div>
                            <div className={cellClass}>{selectedClientCustomer?.fullname}</div>
                        </div>

                    })
                }
            </div>
        </>
    );
}

export default CustomersContent;