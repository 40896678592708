import { useApolloClient, useMutation } from "@apollo/client";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/user.context";
import { LOGOUT_CONTACT_MUTATION } from "../api/mutations/contact.mutations";

const useLogout = () => {
  const { setUser } = useContext(UserContext);
  const client = useApolloClient();
  const navigate = useNavigate();
  const [logout] = useMutation(LOGOUT_CONTACT_MUTATION);

  const handleLogout = async () => {
    try {
      // Perform GraphQL mutation to logout if necessary
      // await client.mutate({ mutation: YOUR_LOGOUT_MUTATION });

      // Clear cache and reset store
      setUser(null);
      await logout();
      await client.clearStore();
      await client.resetStore();
      // Redirect to login page or homepage after successful logout
      navigate("/login");
    } catch (error) {}
  };
  return handleLogout;
};

export default useLogout;
