import { Link, useNavigate } from 'react-router-dom';

const Header1 = (props) => {
    const navigate = useNavigate();
    const { showWhatsCovered, showMyAccount, showFAQs } = props;
    const logo = 'https://www.goevstar.com/Content/images/EVSTAR_dark_logo_transparent_tiny.png';

    return (
        <>
            <header>
                <div className="flex flex-row items-center justify-between py-3 w-full border-b border-solid border-black border-opacity-25 px-3 md:px-0">
                    <img className="cursor-pointer" src={logo} onClick={() => {
                        navigate('/login');
                    }} />
                    {showWhatsCovered && <div>
                        <Link
                            to={'/whatscovered'}
                            className={`flex gap-1 text-md pl-6 py-3 border-b-[1px] border-b-evstarLighGreen/10`}
                        >
                            <div className="underline text-orange-400 text-sm md:text-base sm:text-sm xl:text-lg lg:text-lg">
                                What's Covered?
                            </div>
                        </Link>
                        {/* <a className="underline text-orange-400 text-sm md:text-base sm:text-sm xl:text-lg lg:text-lg" href="whatscovered"></a> */}
                    </div>}
                    {showFAQs && <div>
                        <Link
                            to={'#'}
                            className={`flex gap-1 text-md pl-6 py-3 border-b-[1px] border-b-evstarLighGreen/10`}
                        >
                            <div className="underline text-orange-400 text-sm md:text-base sm:text-sm xl:text-lg lg:text-lg">
                            FAQs?
                            </div>
                        </Link>
                    </div>}
                    {showMyAccount && 

                    <>
                    <Link
                            to={'/login'}
                            className={`flex gap-1 text-md pl-6 py-3 border-b-[1px] border-b-evstarLighGreen/10`}
                        >
                            <div
                        className="text-evstarLighGreen gap-2 cursor-pointer flex flex-row items-center px-5 py-2 border-2 border-solid border-evstarLighGreen hover:bg-evstarLighGreen rounded-xl hover:text-white transition duration-300"
                    >
                        <span className="fa-regular fa-circle-user " />
                        My account
                    </div>
                        </Link>
                    
                    </>
                    }
                </div>
            </header>
        </>
    );
}

export default Header1;