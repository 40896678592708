import { useContext, useEffect, useRef, useState } from "react";
import OutLinedButton from "../buttons/outlined.button.component";
import { data } from '../../utils/claimsData';
import { useNavigate, useParams } from "react-router-dom";
import TEST_ASSET from "../../constants/test/asset.test.constant";
import { useLazyQuery } from "@apollo/client";
import { GET_TICKET_QUERY } from "../../api/queries/ticket.queries";
import useTicket from "../../hooks/useTicket";
import { Spinner } from "@material-tailwind/react";
import { UserContext } from "../../contexts/user.context";

const ClaimDetailContent = () => {
    const cellClass = `w-1/6 overflow-x-clip`;
    const [comment, setComment] = useState('');
    const { claimID } = useParams();
    const [ticket, setTicket] = useState({});
    const [feedbackAndComments, setFeedbackAndComments] = useState([]);
    const [newComment, setNewComment] = useState({}, () => null);
    const [asset, setAsset] = useState(TEST_ASSET);
    const [fillPercent, setFillPercent] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const { addComment } = useTicket();
    const [feedback, setFeedback] = useState("");
    const { _id, number, comments, id } = ticket;
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const [fetchTicket, { error, loading, data }] = useLazyQuery(
        GET_TICKET_QUERY,
        {
            variables: {
                _id,
            },
            onCompleted: (data) => {
                console.log('Data:', data);
                setTicket(data.ticket);
                setAsset(data.ticket.assets[0]);
            },
            fetchPolicy: "network-only",
        }
    );

    useEffect(() => {
        console.log('Ticket:', ticket);
        console.log('Asset:', asset);
    }, [ticket, asset])


    useEffect(() => {
        if (ticket?.status_changes?.length) {
            const statusChanges = ticket.status_changes.map((statusChange) => {
                return statusChange.new_status;
            });
            const resolved = statusChanges.find((status) =>
                status.includes("Resolved")
            );
            if (resolved) return setFillPercent(100);
            const repaired = statusChanges.find((status) =>
                status.includes("Ready for shipment")
            );
            if (repaired) return setFillPercent(67);
            const received = statusChanges.find((status) =>
                status.includes("Received")
            );
            if (received) return setFillPercent(34);
        }
    }, [ticket]);

    useEffect(() => {
        const _id = claimID;
        fetchTicket({ variables: { _id } });
    }, []);

    useEffect(() => {
        fetchTicket({ variables: { _id: claimID } });
    }, [claimID]);

    useEffect(() => {
        setFeedbackAndComments(comments);
    }, [comments]);

    useEffect(() => {
        if (newComment?.id === undefined) return;
        setFeedbackAndComments([...feedbackAndComments, newComment]);
    }, [newComment]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        // Create an Intl.DateTimeFormat instance using the user's locale and your desired options
        const formatter = new Intl.DateTimeFormat("default", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
        console.log('DateString:', dateString);
        return formatter.format(date);
    };


    const handleClick = async (e) => {
        // e.preventDefault();
        if (!feedback) return alert("Please enter feedback.");
        setIsLoading(true);
        const res = await addComment({
            ticket_id: ticket.id,
            newStatus: "Customer Reply",
            body: feedback,
            subject: "Customer Reply",
            tech: "customer-reply",
        });
        if (res?.error) return alert("Error sending feedback.");
        setFeedback("");
        setNewComment(res);
        setIsLoading(false);
    };

    const viewBtnClickHandler = (id) => {
        navigate(`/dispatch-details/${id}`);
    }

    return (
        <>
            <div className=" flex flex-col gap-3">

                <div className="flex flex-col gap-2">
                    <div className="w-full bg-evstarLighGreen text-white text-lg rounded-md py-2 font-bold text-center">
                        Claim Details
                    </div>
                    <div className="w-full overflow-x-auto border-2 border-evstarLandingInputField rounded-xl py-2 px-3">
                        <div className="flex flex-row justify-between border-b-2 font-bold">
                            <div className={cellClass}>Claim #</div>
                            <div className={cellClass}>Claim Status</div>
                            <div className={cellClass}>Symptom</div>
                            <div className={cellClass}>Incident Date</div>
                            <div className={cellClass}>Submitted By</div>
                            <div className={cellClass}>Dispatch Status</div>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div className={cellClass}>{ticket.number_string}</div>
                            <div className={cellClass}>{ticket.status}</div>
                            <div className={cellClass}>{ticket.problem_type}</div>
                            <div className={cellClass}>{ticket.created_at}</div>
                            <div className={cellClass}>{ticket.submitted_by}</div>
                            <div className={`${cellClass} p-2`}>
                                <div className="text-center bg-evstarLighGreen text-white rounded-lg max-w-max px-8 py-1 cursor-pointer text-sm"
                                    onClick={
                                        () => {
                                            viewBtnClickHandler(_id);
                                        }
                                    }
                                >
                                    View
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="w-full bg-evstarLighGreen text-white text-lg rounded-md py-2 font-bold text-center">
                        Associated Location
                    </div>
                    <div className="w-full overflow-x-auto border-2 border-evstarLandingInputField rounded-xl py-2 px-3">
                        <div className="flex flex-row border-b-2 font-bold justify-between">
                            <div className='w-[14%]'>Business Name</div>
                            <div className='w-[14%]'>Site Contact</div>
                            <div className='w-[14%]'>Site Contact #</div>
                            <div className='w-[14%]'>Site Address</div>
                            <div className='w-[14%]'>Site City</div>
                            <div className='w-[14%]'>Site State</div>
                            <div className='w-[14%]'>Site Zip</div>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div className='w-[14%]'>{ticket.customer_business_then_name}</div>
                            <div className='w-[14%]'>{ticket.properties?.["Site Contact First Name"]}</div>
                            <div className='w-[14%]'>{ticket.properties?.["Site Contact Phone Number"]}</div>
                            <div className='w-[14%]'>{asset.properties?.["Charger Location Address Line 1"]}</div>
                            <div className='w-[14%]'>{asset.properties?.["Charger Location City"]}</div>
                            <div className='w-[14%]'>{asset.properties?.["Charger Location State"]}</div>
                            <div className='w-[14%]'>{asset.properties?.["Charger Location Postal Code"]}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="w-full bg-evstarLighGreen text-white text-lg rounded-md py-2 font-bold text-center">
                        Associated Devices
                    </div>
                    <div className="w-full overflow-x-auto border-2 border-evstarLandingInputField rounded-xl py-2 px-3">
                        <div className="flex flex-row justify-between border-b-2 font-bold">
                            <div className="w-1/3 sm:1/4">Serial #</div>
                            <div className="w-1/3 sm:1/4">Device Make</div>
                            <div className="w-1/3 sm:1/4">Device Model</div>
                        </div>
                        {
                            asset.properties &&
                            <div className="flex flex-row justify-between">
                                <div className="w-1/3 sm:1/4">{asset.asset_serial}</div>
                                {/* <div className="w-1/3 sm:1/4">{asset.make}</div>
                                <div className="w-1/3 sm:1/4">{asset.model}</div> */}
                                <div className="w-1/3 sm:1/4">{asset.properties['EV Charger Make']}</div>
                                <div className="w-1/3 sm:1/4">{asset.properties['EV Charger Model']}</div>

                            </div>

                        }
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="w-full bg-evstarLighGreen text-white text-lg rounded-md py-2 font-bold text-center">
                        Comments
                    </div>

                    {Array.isArray(feedbackAndComments) && [...feedbackAndComments]
                        .sort((a, b) => {
                            const dateA = new Date(a.created_at);
                            const dateB = new Date(b.created_at);
                            return dateA - dateB;
                        })
                        .map((c) => {
                            return <div key={c.id} className="flex flex-col gap-3 w-full overflow-x-auto border-2 border-evstarLandingInputField rounded-xl py-2 px-3">
                                <div className="flex flex-row gap-5 text-base">
                                    <div>{c.tech}</div>
                                    <div className="flex flex-row gap-1">
                                        <div>{formatDate(c.created_at || c.updated_at)}</div>
                                    </div>
                                </div>
                                <li>{c.body}</li>
                            </div>
                        })}
                </div>
                {user.role === 'admin' || user.role === 'user' ? <form>
                    <textarea value={feedback} onChange={(e) => setFeedback(e.target.value)} type="text" placeholder="Add Comments..." rows={4} className="w-full rounded-xl border-evstarLandingInputField content-center" />
                    <div className="w-2/4 sm:w-1/5">

                        {isLoading ?
                            <div className="flex flex-row gap-3">
                                <div>
                                    Please Wait
                                </div>
                                <Spinner color="light-green" className="h-6 w-6" />
                            </div> :
                            <OutLinedButton text={'Add Comment'} onClick={handleClick} />}
                    </div>
                </form> : <></>}

            </div >
        </>
    );
}
export default ClaimDetailContent;