const FilledButton = (props) => {
    const { text, onClick } = props;
    return (
        <>
            <div className="bg-evstarLighGreen hover:bg-white hover:text-evstarLighGreen hover:border-evstarLighGreen border-2 border-evstarLighGreen  cursor-pointer flex flex-col items-center px-6 py-2.5 rounded-lg w-full text-white text-sm transition duration-300 ease-in-out transform "
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}>
                {text}
            </div>
        </>
    );
}
export default FilledButton;