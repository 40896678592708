import DefaultLayout from "../layouts/default.layout";
import AssetsTableCard from "../components/cards/assetsTable.card";
import LoggedIn from "../layouts/loggedIn.layout";

const Assets = () => {
  return (
    <LoggedIn>
      <DefaultLayout title="Covered Assets / Devices">
        <AssetsTableCard />
      </DefaultLayout>
    </LoggedIn>
  );
};

export default Assets;
