import SelectionInput from "../selection.input.component";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const DEFAULT_OPTIONS = [
  {
    value: "asc",
    name: "Ascending",
  },
  {
    value: "desc",
    name: "Descending",
  },
];

const SortDirectionSelectionInput = ({ entity, onChange, ...otherProps }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentSortParam = searchParams.get("sort") || (entity === "contacts" ? "email" : entity==="customers"? "business_name": "-created_at");
  const isDescending = currentSortParam.startsWith("-");

  // Initialize state based on the current URL search parameter
  const [sortDirection, setSortDirection] = useState(
    isDescending ? "desc" : "asc"
  );

  // Effect to update the URL search parameters when sortDirection changes
  useEffect(() => {
    const newSortParam = `${
      sortDirection === "desc" ? "-" : ""
    }${currentSortParam.replace(/^-/, "")}`;
    searchParams.set("sort", newSortParam);
    setSearchParams(searchParams);
  }, [sortDirection, currentSortParam, setSearchParams]);

  const handleChange = (e) => {
    setSortDirection(e.target.value);
    // Call the parent's onChange handler, if provided
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <SelectionInput
      label="Sort Direction"
      value={sortDirection} // Controlled component should have value prop
      onChange={handleChange}
      {...otherProps}
    >
      {DEFAULT_OPTIONS.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </SelectionInput>
  );
};

export default SortDirectionSelectionInput;
