import { useMutation } from "@apollo/client";
import { CREATE_TICKET_MUTATION } from "../../api/mutations/ticket.mutations";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/user.context";

const SubmitClaimButton = ({
  formFieldsState,
  selectedAssetState,
  assetOptionsState,
}) => {
  const { user } = useContext(UserContext);
  const [createTicket] = useMutation(CREATE_TICKET_MUTATION);
  const [assetOptions, setAssetOptions] = assetOptionsState;
  const [selectedAsset, setSelectedAsset] = selectedAssetState;
  const [formFields, setFormFields] = formFieldsState;
  const { asset_serial, make, model, asset_type, comment, customer_id } = formFields;
  const handleClick = (e) => {
    e.preventDefault();
    if (!asset_serial) return alert("Please enter an asset serial number.");
    if (!make) return alert("Please enter the make of the asset.");
    if (!model) return alert("Please enter the model of the asset.");
    if (!comment) return alert("Please enter a comment.");
    const coveredAsset = assetOptions?.find(
      (asset) => asset.asset_serial.toLowerCase() === asset_serial.toLowerCase()
    );

    if (!selectedAsset?.id && !coveredAsset) {
      return alert(
        "Serial number was not found. Please contact your administrator."
      );
    }
    const warrantyEndDate =
      selectedAsset?.warranty_end_date || coveredAsset?.warranty_end_date || "";
    // Check for expired warranty
    if (!warrantyEndDate || warrantyEndDate < new Date()) {
      if (
        !window.confirm(
          `Serial number ${selectedAsset?.asset_serial || coveredAsset?.asset_serial || ""
          } has an expired warranty, if you would like to proceed with the claim, you will be charged for the repair. If you feel there has been an error please contact us at codicare@codiworldwide.com`
        )
      )
        return;
    }

    try {
      createTicket({
        variables: {
          problem_type: "Other",
          comment: comment,
          asset_id: selectedAsset?.id,
          contact_id: user.id,
          asset_serial:
            selectedAsset?.asset_serial || coveredAsset?.asset_serial,
          make: selectedAsset?.make || coveredAsset?.make,
          model: selectedAsset?.model || coveredAsset?.model,
          asset_type: selectedAsset?.asset_type || coveredAsset?.asset_type,
          customer_id: (user?.role == "codi admin") ? null : user?.customer_id
        },
        onCompleted: (res) => {
          alert(`Claim submitted. Ticket #${res.createTicket.number}`);
          setSelectedAsset({});
          setAssetOptions([]);
          setFormFields({
            asset_serial: "",
            make: "",
            model: "",
            asset_type: "",
            comment: "",
          });
        },
      });
    } catch (error) {
      console.log(error);
      error?.message ? alert(error.message) : alert("Contact system admin.");
    }
  };
  return (
    <button
      className="hover:bg-codiRed hover:text-white flex justify-center text-center items-center text-codiRed border-[1px] border-codiRed px-4 py-2 rounded-lg"
      onClick={handleClick}
    >
      Submit Claim
    </button>
  );
};

export default SubmitClaimButton;
