import { useContext, useEffect, useState } from "react";
import Footer1 from "../components/footer.component";
import Header1 from "../components/header.component1";
import MenuBarMobile from "../components/sidebar/menubar.mobile";
import Sidebar from "../components/sidebar/sidebar.component";
import Heading from "../components/shared/heading.landing";
import CoveredDevicesContent from "../components/content/covered.devices.content";
import LoggedIn1 from "../layouts/loggedIn.layout1";
import { UserContext } from "../contexts/user.context";
import useAsset from "../hooks/useAsset";
import { useSearchParams } from "react-router-dom";
import SearchOptions from "../components/searchOptions.component";
import ClaimsPagination from "../components/pagination1.component";

const DEFAULT_SEARCH_OPTIONS = {
  search: "",
  sortBy: "number",
  sortDirection: "asc",
  statusFilter: [],
  page: 1,
  maxPages: 10,
};


const CoveredDevices = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { user } = useContext(UserContext);
  const { getAssets, getAssetCount } = useAsset();
  const [searchParams] = useSearchParams();
  const [assets, setAssets] = useState([]);
  const [assetTotal, setAssetTotal] = useState(0);
  const [searchOptions, setSearchOptions] = useState(DEFAULT_SEARCH_OPTIONS);
  const { search, sortBy, sortDirection, statusFilter, maxPages } =
    searchOptions;
  const [page, setPage] = useState(+searchParams?.get("page") || 1);

  useEffect(() => {
    if (!user?.id) return;
    if (!searchParams.toString()) return;
    const fetchAndSetAssets = async () => {
      if (searchOptions.search !== "") {
        searchParams.append("page", "1");
      }

      const AssetInput = {
        warranty: true,
        //customer_id: user?.customer_id,
      };
      const fetchedAssets = await getAssets({
        searchParams: searchParams.toString(),
        AssetInput:AssetInput,
      }, );
      console.log('Fetched Assets:', fetchedAssets);
      setAssets(fetchedAssets);
    };
    fetchAndSetAssets();
    const fetchAssetCount = async () => {
      const AssetInput = {
        warranty: true,
      };
      const fetchedAssetCount = await getAssetCount({
        searchParams: searchParams.toString(),
        AssetInput:AssetInput,
      });
      setAssetTotal(fetchedAssetCount);
    };
    fetchAssetCount();
  }, [searchParams, user]);

  return (
    <>
      <LoggedIn1>
        <div className="bg-white flex flex-col items-center min-h-screen">
          <div className="lg:w-5/6 w-full">
            <Header1 showWhatsCovered />
            <div className="hidden">
              <SearchOptions
                type="assets"
                searchOptionsState={[searchOptions, setSearchOptions]}
              />
            </div>
            <div className="h-full ">
              <div className="flex h-full ">
                <MenuBarMobile setter={setShowSidebar} />
                <Sidebar show={showSidebar} setter={setShowSidebar} />
                <div className="flex flex-col w-full min-h-screen p-4 gap-4">
                  <CoveredDevicesContent assets={assets} />
                  <div className="w-full overflow-x-auto">
                    <ClaimsPagination currentPage={page} maxPages={Math.ceil(assetTotal / 25)} />
                  </div>
                </div>
              </div>
            </div>
            <Footer1 />
          </div>
        </div>
      </LoggedIn1>
    </>
  );
}
export default CoveredDevices;