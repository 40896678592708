import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.icon.svg";
import { ReactComponent as UserIcon } from "../assets/icons/user.icon.svg";
import { UserContext } from "../contexts/user.context";
import { useContext } from "react";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  // Create an Intl.DateTimeFormat instance using the user's locale and your desired options
  const formatter = new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  return formatter.format(date);
};

const Comment = ({
  body = "",
  id = 1,
  tech = "",
  created_at = "",
  updated_at = "",
}) => {
  const { user } = useContext(UserContext);
  const commentName = tech === "customer-reply" ? user?.name : tech;
  const formatCreatedAt = formatDate(created_at || updated_at);
  return (
    <div className="p-4 border-dashed border-codiTableHeaders border-[2px] rounded-xtraLarge">
      <div className="gap-6 my-2 flex flex-row h-8 items-center font-interMedium text-base text-codiTableHeaders">
        <div className="gap-1 flex flex-row">
          <UserIcon className="fill-codiTableHeaders" />
          {commentName}
        </div>
        <div className="gap-1 flex flex-row">
          <CalendarIcon className="fill-codiTableHeaders" />
          {formatCreatedAt}
        </div>
      </div>
      <div className="font-interMedium text-sm">{body}</div>
    </div>
  );
};

export default Comment;
