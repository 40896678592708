import { useLazyQuery } from "@apollo/client";
import {
  GET_ASSETS,
  GET_ASSETS_COUNT_QUERY,
} from "../api/queries/asset.queries";
import { useContext } from "react";
import { UserContext } from "../contexts/user.context";

const useAsset = () => {
  const { user } = useContext(UserContext);
  const [assetsQuery] = useLazyQuery(GET_ASSETS, {
    fetchPolicy: "network-only",
  });
  const [assetCountQuery] = useLazyQuery(GET_ASSETS_COUNT_QUERY, {
    fetchPolicy: "network-only",
  });

  const getAssetCount = async ({ searchParams, AssetInput }) => {
    try {
      if (user?.role !== "evstar admin") {
        AssetInput.customer_id = user?.customer_id;
      }
      let variables = {
        AssetInput: {
          ...AssetInput,
          // customer_id: null,
          // warranty: true,
        },
        searchParams
      }
      const response = await assetCountQuery({
        variables: variables
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.getAssetCount || 0;
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getAssets = async ({ searchParams, AssetInput }) => {
    try {
      if (user?.role !== "evstar admin") {
        AssetInput.customer_id = user?.customer_id;
      }
      let variables = {
        AssetInput: {
          ...AssetInput,
          // customer_id: null,
          // warranty: true,
        },
        searchParams
      }
      console.log('Variables:', variables);

      const response = await assetsQuery({
        variables: variables
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.assets || [];
    } catch (error) {
      console.error(error?.message);
    }
  };

  return { getAssets, getAssetCount };
};

export default useAsset;
