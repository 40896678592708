import LoggedOutLayout from "../layouts/loggedOut.layout";
import emblemImage from "../assets/images/codi-emblem.png";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ResetPasswordButton from "../components/buttons/resetPassword.button.component";
import Header1 from "../components/header.component1";

const DEFAULT_PASSWORD_FORM = {
    password: "",
    confirmPassword: "",
};

const PasswordReset1 = () => {
    const [passwordForm, setPasswordForm] = useState(DEFAULT_PASSWORD_FORM);
    const { password, confirmPassword } = passwordForm;
    const handleChange = (e) => {
        setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const isEnrollment = queryParams.has('enrollment');
    const isNewAccount = queryParams.has('new');

    return (
        <>
            <div className="bg-white flex flex-col items-center min-h-screen">
                <div className="md:w-5/6 w-full">
                    <Header1 showMyAccount />
                    <div className="min-h-[80vh] flex justify-center sm:py-6">
                        <div className="sm:px-8 py-10 sm:py-4 flex flex-col gap-6 w-4/5 sm:w-2/5 sm:text-center sm:bg-evstarLighGreen/5 rounded-xl">
                            <div className="font-bold text-xl">{isNewAccount? `Create `:`Reset `}Your Password</div>
                            <div>
                                <div>Enter new secure password</div>
                                <input
                                    className="border border-codiTableHeaders rounded p-2 text-base font-interMedium"
                                    type="password"
                                    autoComplete="off"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <div>Confirm password</div>
                                <input
                                    className="border border-codiTableHeaders rounded p-2 text-base font-interMedium"
                                    type="password"
                                    autoComplete="off"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <ResetPasswordButton
                                    passwordFormState={[passwordForm, setPasswordForm]}
                                    isEnrollment={isEnrollment}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <LoggedOutLayout>
                <div className="gap-y-6 flex flex-col bg-white p-8 justify-center items-center">
                    <img src={emblemImage} alt="CODi Emblem" style={{ width: 200 }} />
                    <h1 className="text-center text-lg font-interExtraBold text-codiDarkGray">
                        {isEnrollment ? "Create Password" : "Reset Password"}
                    </h1>
                    <div className="gap-y-2 flex flex-col">
                        <label className="text-codiTableHeaders text-center">
                            Enter a new and secure password
                        </label>
                        <input
                            className="border border-codiTableHeaders rounded p-2 text-base font-interMedium"
                            type="password"
                            autoComplete="off"
                            name="password"
                            value={password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="gap-y-2 flex flex-col">
                        <label className="text-codiTableHeaders text-center">
                            Confirm Password
                        </label>
                        <input
                            className="border border-codiTableHeaders rounded p-2 text-base font-interMedium"
                            type="password"
                            autoComplete="off"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="gap-x-12 flex flex-row">
                        <ResetPasswordButton
                            passwordFormState={[passwordForm, setPasswordForm]}
                            isEnrollment={isEnrollment}
                        />
                    </div>
                </div>
            </LoggedOutLayout> */}
        </>
    );
};

export default PasswordReset1;
