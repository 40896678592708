import WhatsCoveredContent from "../components/content/whats.covered.content";
import Footer1 from "../components/footer.component";
import Header1 from "../components/header.component1";

const WhatsCovered = ()=>{
    return (
        <>
            <div className="bg-white flex flex-col items-center min-h-screen">
                <div className="md:w-3/4 w-full">
                    <Header1 showFAQs/>
                        <WhatsCoveredContent/>
                    <Footer1/>
                </div>
            </div>
        </>
    );
}

export default WhatsCovered;