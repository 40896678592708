import { Routes, Route } from "react-router-dom";
import "./App.css";
import Assets from "./pages/assets.page";
import Asset from "./pages/asset.page";
import Claims from "./pages/claims.page";
import Claim from "./pages/claim.page";
import Dashboard from "./pages/dashboard.page";
import CreateClaim from "./pages/createClaim.page";
import SendPasswordReset from "./pages/sendPasswordReset";
import PasswordReset from "./pages/passwordReset.page";
import Support from "./pages/support.page";
import TermsConditions from "./pages/TermsConditions.page";
import ShippingLabels from "./pages/shippingLabels.page";
import AccountInformation from "./pages/accountInformation.page";
import ChangePassword from "./pages/changePassword.page";
import ManageContacts from "./pages/manageContacts.page";
import ContactSave from "./pages/contactSave.page";
import CustomerCreate from "./pages/customerCreate.page";
import ContactEdit from "./pages/contactEdit.page";
import CustomerEdit from "./pages/customerEdit.page";
import Customers from "./pages/customers.page";
import Login1 from "./pages/login.page1";
import Login from "./pages/login.page";
import Register from "./pages/register.page";
import Home from "./pages/home.page";
import WhatsCovered from "./pages/whats.covered.page";
import SubmitClaims from "./pages/submitClaims.page";
import Account from "./pages/account.page";
import CoveredDevices from "./pages/coveredDevices.page";
import ViewClaims from "./pages/view.claims.page";
import Customers1 from "./pages/customers.page1";
import ClaimDetail1 from "./pages/claim.detail.page";
import DispatchDetail from "./pages/dispatch.detail";
import SendPasswordReset1 from "./pages/sendPasswordReset1";
import PasswordReset1 from "./pages/passwordReset.page1";
import ManageContacts1 from "./pages/manageContacts1.page";
import { ContactSave1 } from "./pages/contactSave1.page";

function App() {
  document.getElementById("root").classList.add("bg-mainBG");
  return (
    <Routes>
      <Route path="/login" element={<Login1 />} />
      <Route path="/register" element={<Register />} />
      <Route path="/whatscovered" element={<WhatsCovered />} />
      <Route path="/submit-claim" element={<SubmitClaims />} />
      <Route path="/view-claims" element={<ViewClaims />} />
      {/* individual Claim Detail Page */}
      <Route path="/view-claims/:claimID" element={<ClaimDetail1 />} />
      <Route path="/dispatch-details/:claimID" element={<DispatchDetail />} />
      <Route path="/covered-devices" element={<CoveredDevices />} />
      <Route path="/account/*" element={<Account />} />
      <Route path="/" element={<Home />} />
      <Route path="customers" element={<Customers1 />} />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="dashboard" element={<ViewClaims />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="assets/:assetId" element={<Asset />} />
      <Route path="assets" element={<Assets />} />
      <Route path="claims/:claimId" element={<Claim />} />
      <Route path="claims" element={<Claims />} />
      <Route path="create-claim" element={<CreateClaim />} />
      <Route path="/send-password-reset" element={<SendPasswordReset1 />} />
      <Route path="password-reset/:token" element={<PasswordReset1 />} />
      <Route path="support" element={<Support />} />
      <Route path="shipping-labels" element={<ShippingLabels />} />
      <Route path="account-info" element={<AccountInformation />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="manage-contacts/:customerId" element={<ManageContacts1 />} />
      <Route path="manage-contacts/:customerId" element={<ManageContacts />} />
      {/* <Route path="customers" element={<Customers />} /> */}
      <Route path="contact-save" element={<ContactSave1 />} />
      <Route path="contact-save" element={<ContactSave />} />
      <Route path="customer-create" element={<CustomerCreate />} />
      <Route path="contact-edit/:contactId" element={<ContactEdit />} />
      <Route path="customer-edit/:customerId" element={<CustomerEdit />} />
      <Route path="programterms-consumerelectronics" element={<TermsConditions />} />
    </Routes>
  );
}

export default App;
