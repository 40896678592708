import Navbar from "../components/navbar.component";
import Header from "../components/header.component";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import useCustomer from "../hooks/useCustomer";
import { UserContext } from "../contexts/user.context";
import { CustomerContext } from "../contexts/customer.context";
import emblemImage from "../assets/images/codi-emblem.png";


const LoggedIn1 = ({ children }) => {
    const { getCustomer } = useCustomer();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { customer } = useContext(CustomerContext);
    useEffect(() => {
        if (user?.customer_id) getCustomer(user.customer_id);
        if (!user) navigate("/login");
    }, [user]);

    const [isMobileView, setIsMobileView] = useState(false);
    // State to control Navbar visibility for mobile view
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(getInnerWidth() <= 768); // Adjust the breakpoint as needed
        };

        const getInnerWidth = () => window.screen.width * (/*window.devicePixelRatio || */1);



        handleResize(); // Check initial width
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // State to control the height of the Navbar
    const [navbarHeight, setNavbarHeight] = useState("60");

    // Toggle Navbar height when collapsing or extending
    useEffect(() => {
        if (isNavbarCollapsed) {
            setNavbarHeight("10%");
        } else {
            setNavbarHeight("60%");
        }
    }, [isNavbarCollapsed]);


    return (
        <>
            {isMobileView ? (
                <div className="h-screen w-full mobile-wrapper hidden">
                    <Header
                        businessName={customer?.business_name ? customer?.business_name : ""}
                        username={user?.name ? user?.name : ""}
                    />
                    <div className='mobile-menu-wraper'>
                        <div className="  mobile-view z-10" style={{ backgroundColor: "#e9e9e9", transition: "height 0.3s " }} onClick={() => setIsNavbarCollapsed(!isNavbarCollapsed)}>
                            {/* Image for mobile view */}
                            <div className="w-1/6 md:w-1/6 p-2">
                                <img
                                    src={emblemImage}
                                    alt="CODI Image"
                                    className="w-200 h-auto cursor-pointer md:w-20 md:h-auto md:cursor-auto"
                                />
                                <span class="navbar-toggler-icon"></span>
                            </div>
                        </div>

                        {/* Collapsible Navbar for mobile view */}
                        <div className={`w-5/6 md:w-5/6 nav-container ${isNavbarCollapsed ? 'hidden' : ''}`}>
                            <Navbar />
                        </div>
                    </div>
                    {children}
                </div>
            ) : (
                <div className="h-screen w-full hidden">
                    <Header
                        businessName={customer?.business_name ? customer?.business_name : ""}
                        username={user?.name ? user?.name : ""}
                    />
                    <div className="flex flex-row h-full z-10">
                        <Navbar />
                        {children}
                    </div>
                </div>)}
            <div>
                {children}
            </div>
        </>
    );
};

export default LoggedIn1;