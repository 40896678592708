import { gql } from "@apollo/client";

export const GET_CUSTOMERS_QUERY = gql`
  query GetCustomers($sort: String, $codi_customer: Boolean) {
    customers(sort: $sort, codi_customer: $codi_customer) {
      id
      business_name
      codi_customer
    }
  }
`;

export const GET_RS_CUSTOMER_QUERY = gql`
  query GetRSCustomer($id: Int!) {
    getRSCustomer(id: $id) {
      contacts {
        id
        name
        address1
        address2
        city
        state
        zip
        email
        phone
        mobile
        customer_id
        account_id
      }
    }
  }
`;



export const FETCH_CUSTOMERS_COUNT_QUERY = gql`
  query fetchCustomerCount($searchParams: String) {
    fetchCustomerCount(searchParams: $searchParams) 
  }
`;
export const FETCH_CUSTOMERS_QUERY = gql`
  query fetchCustomers($searchParams: String) {
    fetchCustomers(searchParams: $searchParams) {
      id
      fullname
      business_name
      email
      address
      city
    }
  }
`;


export const GET_CUSTOMER_QUERY = gql`
  query GetCustomer($id: Int!) {
    customer(id: $id) {
      _id
      id
      firstname
      lastname
      fullname
      business_name
      email
      phone
      mobile
      created_at
      updated_at
      address
      address_2
      city
      state
      zip
      business_and_full_name
      reseller
      client_customer_id
    }
  }
`;
