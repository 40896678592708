import { useContext, useEffect, useState } from "react";
import Footer1 from "../components/footer.component";
import Header1 from "../components/header.component1";
import MenuBarMobile from "../components/sidebar/menubar.mobile";
import Sidebar from "../components/sidebar/sidebar.component";
import ViewClaimsContent from "../components/content/view.claims.content";
import ClaimsPagination from "../components/pagination1.component";
import { UserContext } from "../contexts/user.context";
import useTicket from "../hooks/useTicket";
import { useSearchParams } from "react-router-dom";
import SearchOptions from "../components/searchOptions.component";
import { Spinner } from "@material-tailwind/react";
import useCustomer from "../hooks/useCustomer";
import LoggedIn from "../layouts/loggedIn.layout";
import LoggedIn1 from "../layouts/loggedIn.layout1";


const DEFAULT_SEARCH_OPTIONS = {
    search: "",
    sortBy: "number",
    sortDirection: "asc",
    statusFilter: [],
    page: 1,
    maxPages: 10,
};

const ViewClaims = ({
    title = "",
    TicketInput,
    totalClaimsState,
    children,
}) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [totalClaims, setTotalClaims] = totalClaimsState || [0, () => null];
    const { user } = useContext(UserContext);
    const { getTickets, getTicketCount } = useTicket();
    const [searchParams] = useSearchParams();
    const [tickets, setTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ticketCount, setTicketCount] = useState(0);
    const [searchOptions, setSearchOptions] = useState(DEFAULT_SEARCH_OPTIONS);
    const { search, sortBy, sortDirection, statusFilter, maxPages } =
        searchOptions;
    const [page, setPage] = useState(+searchParams?.get("page") || 1);

    useEffect(() => {
        if (!user?.id) return;
        if (!searchParams.toString()) return;
        const fetchAndSetTickets = async () => {
            setIsLoading(true);
            if (searchOptions.search !== "") {
                searchParams.append("page", "1");
            }
            const fetchedTickets = await getTickets({
                searchParams: searchParams.toString(),
                TicketInput: { ...TicketInput },
            });
            setTickets(fetchedTickets);
            setTotalClaims(fetchedTickets?.length);
            setIsLoading(false);
        };
        fetchAndSetTickets();
        const fetchTicketCount = async () => {
            const fetchedTicketCount = await getTicketCount({
                searchParams: searchParams.toString(),
                TicketInput: { ...TicketInput },
            });
            setTicketCount(fetchedTicketCount);
        };
        fetchTicketCount();
    }, [searchParams, user, TicketInput]);

    return (
        <>
            <LoggedIn1>
                <div className="bg-white flex flex-col items-center min-h-screen">
                    <div className="lg:w-5/6 w-full">
                        <Header1 showWhatsCovered />
                        <div className="hidden">
                            <SearchOptions
                                type="claims"
                                searchOptionsState={[searchOptions, setSearchOptions]}
                            />
                        </div>
                        <div className="h-full ">
                            <div className="flex f-full ">
                                <MenuBarMobile setter={setShowSidebar} />
                                <Sidebar show={showSidebar} setter={setShowSidebar} />
                                <div className="flex flex-col flex-grow w-full md:w-full min-h-screen p-4 gap-4">
                                    <ViewClaimsContent claims={tickets} isLoading={isLoading} />
                                    <div className="w-full overflow-x-auto">
                                        <ClaimsPagination currentPage={page} maxPages={Math.ceil(ticketCount / 25)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer1 />
                    </div>
                </div>
            </LoggedIn1>
        </>
    );
}
export default ViewClaims;