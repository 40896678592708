import FilledButton from "../buttons/filled.button.button.component";
import OutLinedButton from "../buttons/outlined.button.component";
import { useNavigate } from 'react-router-dom';

const RegisterationForm = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className=" p-12 min-h-[80vh] ">
                <form
                    onSubmit={() => { }}
                    className="flex flex-col item  h-5/6 min-h-[60vh] justify-between"
                >
                    <div className="flex flex-col gap-6">
                        <div className="text-md font-normal sm:text-3xl ">
                            Register Your Account
                        </div>
                        <div className="flex flex-col">
                            <p> Enter your Registration Code </p>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Registaration Code"
                                    maxLength="128"
                                    className="rounded-sm h-6 p-1 border border-solid border-evstarInputField w-64 sm:w-72" />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row gap-32">
                        <div className="w-20">
                            <OutLinedButton text={'Back'} onClick={() => {
                                navigate(-1);
                            }} />
                        </div>
                        <div className="w-20">
                            <FilledButton text={'Validate'} />
                        </div>
                    </div>
                </form>
                <div className="pt-8">
                    <hr />
                </div>
            </div>
        </>
    );
}

export default RegisterationForm;