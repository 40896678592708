import { useNavigate } from 'react-router-dom';

const HomeContent = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="min-h-[80vh]">
                <div className="flex flex-col gap-6">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col w-2/4 bg-[#F5F5F5] py-8">
                            <div className="lg:text-4xl text-sm p-12">
                                Hi. How can we help you?
                            </div>
                            <div className=" flex flex-row justify-center flex-wrap gap-2 ">
                                {/* <div onClick={() => {
                                    console.log('Register Button Tapped');
                                    navigate('/register');
                                }}
                                    className="gap-1 cursor-pointer flex flex-row border-2 border-solid border-evstarLighGreen justify-center items-center px-6 py-2 bg-evstarLighGreen rounded-xl text-white text-base hover:bg-white hover:text-evstarLighGreen hover:border-2 hover:border-solid hover:border-evstarLighGreen transition duration-300">
                                    <span className="fa-regular fa-square-check " />
                                    First time login
                                </div> */}
                                <div onClick={() => {

                                    navigate('/login');
                                }}
                                    className="border-2 border-solid border-evstarLighGreen cursor-pointer items-center px-4 py-2 rounded-xl text-evstarLighGreen text-base hover:bg-evstarLighGreen hover:text-white transition duration-300">
                                    Login to my account
                                </div>
                            </div>
                        </div>
                        <div className="w-2/4">
                            <img className="h-full object-cover" src="https://claims.goevstar.com/Content/images/hero.png" alt="" />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-col items-center text-xl p-4 text-center">
                            We offer the protection you can trust
                        </div>
                        <div className="flex flex-col items-center text-lg text-evstarGreyish">
                            Here's what you can expect
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between p-6 md:p-0 md:gap-0 gap-4">
                        <div className="w-full md:w-[24%] p-5 flex flex-col gap-2 flex-wrap border-2 border-solid border-evstarLighGreen rounded-2xl bg-[#F5F5F5]">
                            <div className="flex flex-row gap-2">
                                <span className="fa-regular fa-circle-user text-evstarLighGreen" style={{ fontSize: '32px' }} />
                                <div className=" text-lg">
                                    Set Up Your Account
                                </div>
                            </div>
                            <div className="text-base ">
                                With a quick few steps, you'll be able to log in. Once you're logged in, you can view your account.
                            </div>
                        </div>
                        <div className=" w-full md:w-[24%] p-5 flex flex-col gap-2 border-2 border-solid border-evstarLighGreen rounded-2xl bg-[#F5F5F5]">
                            <div className="flex flex-row gap-2">
                                <span className="fa-regular fa-file-excel text-evstarLighGreen" style={{ fontSize: '32px' }} />
                                <div className=" text-lg">
                                    Start a Claim
                                </div>
                            </div>
                            <div className="text-base ">
                                You'll select your product and tell us what's wrong and when it happened. You'll pay your service fee, if there is one, and submit your claim.
                            </div>
                        </div>
                        <div className=" w-full md:w-[24%] p-5 flex flex-col gap-2 border-2 border-solid border-evstarLighGreen rounded-2xl bg-[#F5F5F5]">
                            <div className="flex flex-row gap-2">
                                <span className="fa-regular fa-rectangle-list text-evstarLighGreen" style={{ fontSize: '32px' }} />
                                <div className=" text-lg">
                                    View a Claim Status
                                </div>
                            </div>
                            <div className="text-base ">
                                You can also view a claim status by accessing the dashboard and selecting your device.
                            </div>
                        </div>
                        <div className=" w-full md:w-[24%] p-5 flex flex-col gap-2 border-2 border-solid border-evstarLighGreen rounded-2xl bg-[#F5F5F5]">
                            <div className="flex flex-row gap-2">
                                <span className="fa-regular fa-face-grin-beam text-evstarLighGreen" style={{ fontSize: '32px' }} />
                                <div className="text-lg">
                                    Next Steps
                                </div>
                            </div>
                            <div className="text-base ">
                                Depending on your device, you may see instructions on shipping your device back to us for repair, or you may be receiving a new device. We will make sure you're taken care of quickly and efficiently.
                            </div>
                        </div>
                    </div>
                    <div className="py-4">
                        <hr />
                    </div>
                </div>
            </div>
        </>
    );
}
export default HomeContent;