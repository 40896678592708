import { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_CUSTOMER_QUERY } from "../api/queries/customer.queries";
import { CustomerContext } from "../contexts/customer.context";

const useCustomer = () => {
  const { setCustomer } = useContext(CustomerContext);
  const [customerQuery] = useLazyQuery(GET_CUSTOMER_QUERY, {
    onCompleted: (data) => {
      console.log('OnCompleted Called in (useCustomer):',data);
      setCustomer(data?.customer);
    },
  });
  const getCustomer = async (customerId) => {
    if (!customerId) return;
    customerQuery({ variables: { id: customerId } });
  };

  return { getCustomer };
};

export default useCustomer;
