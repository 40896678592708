import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark.icon.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrowDropUp.icon.svg";

const POSITION_MAP = {
  1: {
    style: "left-0 items-start",
  },
  2: {
    style: "left-1/3 items-center",
  },
  3: {
    style: "left-2/3 items-center",
  },
  4: {
    style: "right-0 items-end",
  },
};

const TimelinePoint = ({ checkmark = false, position = 1, children }) => {
  return (
    <div className={`w-1/4 flex flex-col items-center text-center`}>
      <div className="relative h-full flex items-center">
        <div
          className={`${
            checkmark ? "bg-cyan-100" : "bg-lightGray"
          } bg-clip-padding text-center border-2 border-transparent flex justify-center items-center w-[60px] h-[60px] text-base text-codiBlue rounded-full`}
        >
          {checkmark ? (
            <CheckmarkIcon className="fill-white bg-codiBlue rounded-full m-0" />
          ) : (
            <p className="text-xl font-interExtraBold text-white">{position}</p>
          )}
        </div>
      </div>
      <div
        className={`flex flex-col text-center justify-center items-center ${
          checkmark ? "text-codiBlue" : "text-black"
        } font-interMedium`}
      >
        <ArrowUpIcon
          className={`${checkmark ? "fill-codiBlue" : "fill-black"}`}
        />
        {children}
      </div>
    </div>
  );
};

export default TimelinePoint;
