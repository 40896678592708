const getPaginationPages = (maxPages, currentPage) => {
  const range = (start, end) =>
    Array.from({ length: end - start + 1 }, (_, i) => start + i);
  let pages = [];
  // Always start with the first page.
  pages.push(1);
  // Add the range of pages around the current page.
  const startRange = Math.max(currentPage - 3, 2);
  const endRange = Math.min(currentPage + 3, maxPages - 1);
  pages.push(...range(startRange, endRange));
  // If there's a gap between the current page range and the last page, add an ellipsis.
  if (currentPage + 3 < maxPages - 1) pages.push("...");
  // Always end with the last page.
  pages.push(maxPages);
  return pages;
};

export default getPaginationPages;
