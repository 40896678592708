import { ReactComponent as LeftArrowIcon } from "../assets/icons/leftArrow.icon.svg";
import { ReactComponent as RightArrowIcon } from "../assets/icons/rightArrow.icon.svg";
import { useState, useEffect } from "react";
import getPaginationPages from "../utils/getPaginationPages.utils";
import { useSearchParams } from "react-router-dom";

const PAGE_SIZE = 10;

const PaginationBar = ({
  count = 1,
  maxPages = 1,
  handleChange = () => { },
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pages, setPages] = useState([]);
  const currentPage = +searchParams.get("page") || 1;
  const pageCount = Math.ceil(count / PAGE_SIZE);
  useEffect(() => {
    if (!(maxPages > 1)) return setPages([1]);
    setPages(getPaginationPages(maxPages, currentPage));
  }, [maxPages, currentPage]);

  const nextPage = () => {
    const next = currentPage === maxPages ? 1 : currentPage + 1;
    searchParams.set("page", next);
    setSearchParams(searchParams);
  };
  const prevPage = () => {
    const prev = currentPage === 1 ? maxPages : currentPage - 1;
    searchParams.set("page", prev);
    setSearchParams(searchParams);
  };

  const otherPage = (page) => {
    searchParams.set("page", page);
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-row flex-nowrap items-center gap-2">
      <LeftArrowIcon
        onClick={prevPage}
        className="hover:cursor-pointer hover:bg-white hover:fill-black bg-black fill-white rounded-md w-12 h-12"
      />
      {pages?.map((page, index) => {
        if (index === 1 && page !== 2)
          return (
            <div
              className={` rounded font-interMedium text-base h-10 w-10 flex items-center justify-center`}
            >
              ...
            </div>
          );
        if (index === pages?.length - 2 && page !== maxPages - 1)
          return (
            <div
              className={`rounded font-interMedium text-base h-10 w-10 flex items-center justify-center`}
            >
              ...
            </div>
          );
        return (
          <div
            onClick={() => otherPage(page)}
            key={`${page}-${index}`}
            className={`${currentPage === page ? "bg-lightGray" : ""
              } hover:cursor-pointer hover:bg-lightGray rounded font-interMedium text-base h-10 w-10 flex items-center justify-center`}
          >
            {page}
          </div>
        );
      })}
      <RightArrowIcon
        onClick={nextPage}
        className="hover:cursor-pointer hover:bg-white hover:fill-black bg-black fill-white rounded-md w-12 h-12"
      />
    </div>
  );
};

export default PaginationBar;
