import Footer1 from "../components/footer.component";
import RegisterationForm from "../components/forms/register.form";
import Header1 from "../components/header.component1";

const Register = ()=>{
    return (
        <>
            <div className="bg-white flex flex-col items-center min-h-screen">
                <div className="md:w-3/4 w-full">
                    <Header1/>
                    <content >
                        <RegisterationForm/>
                    </content >
                    <Footer1/>
                </div>
            </div>
        </>
    );
}

export default Register;