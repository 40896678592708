import { useContext, useEffect, useRef, useState } from "react";
import OutLinedButton from "../buttons/outlined.button.component";
import { data } from '../../utils/claimsData';
import { useNavigate, useParams } from "react-router-dom";
import TEST_ASSET from "../../constants/test/asset.test.constant";
import { useLazyQuery } from "@apollo/client";
import { GET_TICKET_QUERY } from "../../api/queries/ticket.queries";
import useTicket from "../../hooks/useTicket";
import { Spinner } from "@material-tailwind/react";
import { UserContext } from "../../contexts/user.context";
import { mappings } from "../../utils/dropdown.mappings";

const DispatchDetailContent = () => {
    const cellClass = `w-1/6 overflow-x-clip`;
    const cellClass1 = `w-1/6 overflow-x-clip`;
    const [comment, setComment] = useState('');
    const { claimID } = useParams();
    const [ticket, setTicket] = useState({});
    const [feedbackAndComments, setFeedbackAndComments] = useState([]);
    const [newComment, setNewComment] = useState({}, () => null);
    const [asset, setAsset] = useState(TEST_ASSET);
    const [fillPercent, setFillPercent] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const { addComment } = useTicket();
    const [feedback, setFeedback] = useState("");
    const { _id, number, comments, id } = ticket;
    const { user } = useContext(UserContext);
    const navigate = useNavigate();


    const [fetchTicket, { error, loading, data }] = useLazyQuery(
        GET_TICKET_QUERY,
        {
            variables: {
                _id,
            },
            onCompleted: (data) => {
                console.log('Data:', data);
                setTicket(data.ticket);
                setAsset(data.ticket.assets[0]);
            },
            fetchPolicy: "network-only",
        }
    );

    useEffect(() => {
        console.log('Ticket:', ticket);
        console.log('Asset:', asset);
    }, [ticket, asset])


    useEffect(() => {
        if (ticket?.status_changes?.length) {
            const statusChanges = ticket.status_changes.map((statusChange) => {
                return statusChange.new_status;
            });
            const resolved = statusChanges.find((status) =>
                status.includes("Resolved")
            );
            if (resolved) return setFillPercent(100);
            const repaired = statusChanges.find((status) =>
                status.includes("Ready for shipment")
            );
            if (repaired) return setFillPercent(67);
            const received = statusChanges.find((status) =>
                status.includes("Received")
            );
            if (received) return setFillPercent(34);
        }
    }, [ticket]);

    useEffect(() => {
        const _id = claimID;
        fetchTicket({ variables: { _id } });
    }, []);

    useEffect(() => {
        fetchTicket({ variables: { _id: claimID } });
    }, [claimID]);

    useEffect(() => {
        setFeedbackAndComments(comments);
    }, [comments]);

    useEffect(() => {
        if (newComment?.id === undefined) return;
        setFeedbackAndComments([...feedbackAndComments, newComment]);
    }, [newComment]);

    return (
        <>
             {ticket.id && <div className=" flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                    <div className="w-full bg-evstarLighGreen text-white text-lg rounded-md py-2 font-bold text-center">
                        Claim Details
                    </div>
                    <div className="w-full overflow-x-auto border-2 border-evstarLandingInputField rounded-xl py-2 px-3">
                        <div className="flex flex-row border-b-2 font-bold justify-between">
                            <div className={cellClass}>Claim #</div>
                            <div className={cellClass}>Claim Status</div>
                            <div className={cellClass}>Symptom</div>
                            <div className={cellClass}>Incident Date</div>
                            <div className={cellClass}>Submitted By</div>
                            <div className={cellClass}>Serial #</div>
                            <div className={cellClass}>Make</div>
                            <div className={cellClass}>Model</div>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div className={`${cellClass} cursor-pointer underline`} onClick={()=>{
                                navigate(-1);
                            }}>{ticket.number_string}</div>
                            <div className={cellClass}>{ticket.status}</div>
                            <div className={cellClass}>{ticket.problem_type}</div> {/* Symptom */}
                            <div className={cellClass}>{ticket.created_at}</div> {/* incident Date */}
                            <div className={cellClass}>{ticket.submitted_by}</div> {/* Submitted By */}
                            <div className={cellClass}>{asset.asset_serial}</div> {/* Serial # */}
                            <div className={cellClass}>{asset.properties?.["EV Charger Make"]}</div> {/* Make */}
                            <div className={cellClass}>{asset.properties?.["EV Charger Model"]}</div> {/* Model */}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="w-full bg-evstarLighGreen text-white text-lg rounded-md py-2 font-bold text-center">
                        Dispatch Details
                    </div>
                    <div className="w-full overflow-x-auto border-2 border-evstarLandingInputField rounded-xl py-2 px-3">
                        <div className="flex flex-row border-b-2 font-bold justify-between">
                            <div className={cellClass}>Status</div>
                            <div className={cellClass}>Sub Status</div>
                            <div className={cellClass}>Disposition</div>
                            <div className={cellClass}>Service Date</div>
                            <div className={cellClass}>Service Time</div>
                            <div className={cellClass}>Assigned Service Provider</div>
                            <div className={cellClass}>Assigned Technician</div>
                            <div className={cellClass}>Service Required</div>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div className={cellClass}>{mappings['Dispatch Status'][ticket.properties?.['Dispatch Status']]}</div> {/* Status */}
                            <div className={cellClass}>{mappings['Dispatch Sub Status'][ticket.properties?.['Dispatch Sub Status']]}</div>
                            <div className={cellClass}>{mappings['Dispatch Disposition'][ticket.properties?.['Dispatch Disposition']]}</div>
                            <div className={cellClass}>{ticket.properties?.['Scheduled Visit Date']}</div>
                            <div className={cellClass}>{ticket.properties?.['Scheduled Visit Time']}</div>
                            <div className={cellClass}>{mappings['Assigned Service Network Provider'][ticket.properties?.['Assigned Service Network Provider']]}</div>
                            <div className={cellClass}>{ticket.properties?.['Technician 1 First Name']} {ticket.properties?.['Technician 1 Last Name']}</div>
                            <div className={cellClass}>{mappings['Services Required'][ticket.properties?.['Services Required']]}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="w-full bg-evstarLighGreen text-white text-lg rounded-md py-2 font-bold text-center">
                        Parts Replaced
                    </div>
                    <div className="w-full overflow-x-auto border-2 border-evstarLandingInputField rounded-xl py-2 px-3">
                        <div className="flex flex-row justify-between border-b-2 font-bold">
                            <div className="w-1/3 sm:1/4">Parts Required</div>
                            <div className="w-1/3 sm:1/4">Parts Received</div>
                            <div className="w-1/3 sm:1/4">Part SKU</div>
                            <div className="w-1/3 sm:1/4">Part Description</div>
                            <div className="w-1/3 sm:1/4">Part Status</div>
                        </div>
                        {[...Array(4)].map((_, index) => (
                            ticket.properties?.[`New Part ${index+1} Sku`] && 
                            (
                                <div className="flex flex-row justify-between border-b-2 font-bold">
                                    <div className="w-1/3 sm:1/4">{mappings['Are Parts Needed'][ticket.properties?.['Are Parts Needed']]}</div>
                                    <div className="w-1/3 sm:1/4">__</div>
                                    <div className="w-1/3 sm:1/4">{ticket.properties?.[`New Part ${index+1} Sku`]}</div>
                                    <div className="w-1/3 sm:1/4">{ticket.properties?.[`New Part ${index+1} Description`]}</div>
                                    <div className="w-1/3 sm:1/4">{mappings[`New Part ${index+1} Status`][ticket.properties?.[`New Part ${index+1} Status`]]}</div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </div >}
        </>
    );
}
export default DispatchDetailContent;