import CardTemplate from "./template/card.template.component";
import formatDate from "../../utils/formatDate";
import { useEffect } from "react";

const WarrantyDetailsCard = ({ asset = {} }) => {
  const { warranty_type, warranty_start_date, warranty_end_date } = asset;

  return (
    <CardTemplate title="Warranty Details">
      <div className="flex flex-col gap-4">
        <div className={`flex w-full flex-col`}>
          <label className="uppercase font-interSemiBold text-codiDarkGray text-base">
            Warranty:
          </label>
          <p className="font-interMedium text-codiRed text-base">
            {asset?.warranty_type}
          </p>
        </div>
        <div className="flex flex-row justify-center w-full">
          <div className="flex flex-col justify-start w-full">
            <label className="uppercase font-interSemiBold text-codiDarkGray text-base">
              Start Date:
            </label>
            <p>{formatDate(warranty_start_date)}</p>
          </div>
          <div className="flex flex-col justify-start w-full">
            <label className="uppercase font-interSemiBold text-codiDarkGray text-base">
              End Date:
            </label>
            <p>{formatDate(warranty_end_date)}</p>
          </div>
        </div>
      </div>
    </CardTemplate>
  );
};

export default WarrantyDetailsCard;
