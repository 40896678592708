import CardTemplate from "./template/card.template.component";

const detailLabels = [
  { label: "Make:", value: "make" },
  { label: "Model:", value: "model" },
  { label: "Serial #:", value: "asset_serial" },
  { label: "Asset Tag:", value: "asset_tag" },
];

const DeviceDetailsCard = ({ asset = [] }) => {
  return (
    <CardTemplate title="Device Details">
      <div className="flex flex-row justify-evenly container-header">
        {detailLabels.map(({ label, value }, index) => (
          <div
            key={`${value}-${index}`}
            className={`flex flex-col basis-[${100 / detailLabels.length}%] container-detail`}
          >
            <label className="uppercase font-interSemiBold text-codiDarkGray text-base">
              {label}
            </label>
            <p className="font-interMedium text-codiDarkGray text-base">
              {asset?.[value]}
            </p>
          </div>
        ))}
      </div>
    </CardTemplate>
  );
};

export default DeviceDetailsCard;
