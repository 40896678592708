import DefaultLayout from "../layouts/default.layout";
import CreateClaimFormCard from "../components/cards/createClaimForm.card";
import TEST_ASSET from "../constants/test/asset.test.constant.js";
import LoggedIn from "../layouts/loggedIn.layout";

const CreateClaim = () => {
  return (
    <LoggedIn>
      <DefaultLayout title="Submit Claim">
        <CreateClaimFormCard />
      </DefaultLayout>
    </LoggedIn>
  );
};

export default CreateClaim;
