import { useLazyQuery } from "@apollo/client";
import { GET_SHIPPING_LABELS_QUERY,GET_SHIPPING_LABELS_COUNT_QUERY } from "../api/queries/shipping-label.queries";
import { useContext } from "react";
import { UserContext } from "../contexts/user.context";

const useShippingLabel = () => {
  const { user } = useContext(UserContext);
  const [shippingLabelsQuery] = useLazyQuery(GET_SHIPPING_LABELS_QUERY);
  const [shippingLabelsCountQuery] = useLazyQuery(GET_SHIPPING_LABELS_COUNT_QUERY);

  const getShippingLabels = async ({ searchParams, ShippingLabelInput }) => {

    try {

      const variables = {searchParams, ShippingLabelInput};
      if(user?.role !== "codi admin"){
        variables.ShippingLabelInput = {
          ...ShippingLabelInput,
          customer_id: user?.customer_id
        }
        //ShippingLabelInput.customer_id = user?.customer_id;
      }
      const response = await shippingLabelsQuery({
        variables: variables,
        fetchPolicy: "network-only",
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.shippingLabels || [];
    } catch (error) {
      console.error(error?.message);
      alert(error?.message);
    }
  };

  const getShippingLabelsCount = async ({ searchParams, ShippingLabelInput }) => {

    try {

      const variables = {searchParams, ShippingLabelInput};
      if(user?.role !== "codi admin"){
        variables.ShippingLabelInput = {
          ...ShippingLabelInput,
          customer_id: user?.customer_id
        }
        //ShippingLabelInput.customer_id = user?.customer_id;
      }
      const response = await shippingLabelsCountQuery({
        variables: variables,
        fetchPolicy: "network-only",
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.shippingLabelsCount || [];
    } catch (error) {
      console.error(error?.message);
      alert(error?.message);
    }
  };

  return { getShippingLabels,getShippingLabelsCount };
};

export default useShippingLabel;
