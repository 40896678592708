import CardTemplate from "./template/card.template.component";
import SearchOptions from "../searchOptions.component";
import PaginationBar from "../paginationBar.component";
import ShippingLabelsTable from "../tables/shippingLabels.table.component";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_SHIPPING_LABELS_QUERY } from "../../api/queries/shipping-label.queries";
import { UserContext } from "../../contexts/user.context";
import useShippingLabel from "../../hooks/useShippingLabel";

const DEFAULT_SEARCH_OPTIONS = {
  search: "",
  sortBy: "created_at",
  sortDirection: "desc",
  statusFilter: [],
  page: 1,
  maxPages: 10,
};

const ShippingLabelsTableCard = ({ newShippingLabelState }) => {
  const [newShippingLabel, setNewShippingLabel] = newShippingLabelState || [
    null,
    () => null,
  ];
  const { user } = useContext(UserContext);
  const { getShippingLabels,getShippingLabelsCount } = useShippingLabel();
  const [searchParams] = useSearchParams();
  const [shippingLabels, setShippingLabels] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [searchOptions, setSearchOptions] = useState(DEFAULT_SEARCH_OPTIONS);
  const { search, sortBy, sortDirection, statusFilter, maxPages } =
    searchOptions;
  const [page, setPage] = useState(+searchParams?.get("page") || 1);

  useEffect(() => {
    if (newShippingLabel?._id === undefined) return;
    setShippingLabels([newShippingLabel, ...shippingLabels]);
  }, [newShippingLabel]);

  useEffect(() => {
    if (!user?.id) return;
    if (!searchParams.toString()) return;
    const fetchAndSetShippingLabels = async () => {
      const fetchedShippingLabels = await getShippingLabels({
        searchParams: searchParams.toString(),
      });
      setShippingLabels(fetchedShippingLabels);
    };
    const fetchAndSetShippingLabelsCount = async () => {
      const fetchedShippingLabelsCount = await getShippingLabelsCount({
        searchParams: searchParams.toString(),
      });
      setTotalRecords(fetchedShippingLabelsCount);
    };
    fetchAndSetShippingLabels();
    fetchAndSetShippingLabelsCount()
  }, [searchParams, user]);
  return (
    <CardTemplate>
      <SearchOptions
        type="shippingLabels"
        searchOptionsState={[searchOptions, setSearchOptions]}
      />
      <ShippingLabelsTable shippingLabels={shippingLabels} />
      <PaginationBar currentPage={page} maxPages={Math.ceil(totalRecords/10)} />
    </CardTemplate>
  );
};

export default ShippingLabelsTableCard;
