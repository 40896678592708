import FilledButton from "../buttons/filled.button.button.component";
import OutLinedButton from "../buttons/outlined.button.component";
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import useLogin from "../../hooks/useLogin";

const LoginForm1 = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { loginForm, setLoginForm, handleLogin, loading } = useLogin();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen(!open);
  // const handleLogin = () => {
  //   if (email === 'test@evstar.com' && password === 'ev@123') {
  //     navigate('/view-claims');
  //   } else {
  //     handleOpen();
  //   }
  // }
  const handleChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      <Dialog open={open} handler={handleOpen} size="xs" className="">
        <DialogHeader className="text-evstarLighGreen">
          EvStar
        </DialogHeader>
        <DialogBody>
          invalid email or password, Please try again with correct credentials
        </DialogBody>
        <DialogFooter>
          <Button variant="filled" className="bg-evstarLighGreen" onClick={handleOpen}>
            <span>OK</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <div className="p-12 min-h-[80vh]">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-6">
          <div className="text-md font-normal sm:text-xl">
            Log in with your email address or mobile number and password
          </div>
          <div className="flex flex-row">
            <div >
              <span className="text-base">
                Email Address or Mobile Number
              </span>
              <div>
                {/* Email input Field */}
                <input type="email" name="email" id="email" onChange={handleChange} maxLength="128" className="px-2 h-10 rounded-lg p-1 border border-solid border-evstarInputField w-64 sm:w-72" />
              </div>
              <span className="text-sm text-gray-600">
                The email address or mobile # on your plan
              </span>
            </div>
          </div>
          <div className="flex flex-row">
            <div>
              <span className="text-base">
                Password
              </span>
              <div>
                {/* Password input Field */}
                <input type="password" name="password" id="password" onChange={handleChange} maxLength="128" className="px-2 h-10 rounded-lg p-1 border border-solid border-evstarInputField w-64 sm:w-72" />
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-10 sm:gap-32">
            <div className="w-36">
              <OutLinedButton text={'Back'} onClick={() => {
                navigate(-1);
              }} />
            </div>
            <div className="w-20">
              {isLoading ?
                <div className="px-6">
                  <Spinner color="light-green" className="h-8 w-8" />
                </div> :
                <FilledButton text={'Login'} onClick={handleLogin} />
              }
            </div>
          </div>
          <div>
            <a href="/send-password-reset" className="underline text-defaultHref">Forgot password?</a>
          </div>
        </form>
      </div>
    </>
  );
};
export default LoginForm1;
