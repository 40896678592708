import { useContext, useState } from "react";
import OutLinedButton from "../buttons/outlined.button.component";
import { UserContext } from "../../contexts/user.context";
import { useMutation } from "@apollo/client";
import { PASSWORD_RESET_MUTATION } from "../../api/mutations/contact.mutations";

const ChangePasswordContent = () => {
    const [passwordForm, setPasswordForm] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
    const { currentPassword, newPassword, confirmPassword } = passwordForm;

    const handleChange = (e) => {
        setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
    };

    const token = localStorage.getItem("token");

    const user = useContext(UserContext).user;

    //console.log(user, token);

    const [PasswordReset] = useMutation(PASSWORD_RESET_MUTATION);

    const handleSubmit = () => {

        if (newPassword.length < 8) {
            alert("Password should contain atleast 8 characters");
            return;
        }

        if (/\s/.test(newPassword)) {
            alert("Space literals are not allowed in password!");
            return;
        }

        if (!/\d/.test(newPassword)) {
            alert("Password should contain numbers");
            return;
        }

        if (!/[A-Za-z]/.test(newPassword)) {
            alert("Password should contain alphabets");
            return;
        }

        if (confirmPassword === newPassword) {
            try {
                //PasswordReset(newPassword, token, currentPassword, user.email);
                PasswordReset({
                    variables: {
                        password: newPassword,
                        token: token,
                        currentPassword: currentPassword,
                        email: user.email
                    }, onCompleted: (res) => {
                        alert("Password changed successfully!");
                        console.log(res);
                        setPasswordForm({
                            currentPassword: "",
                            newPassword: "",
                            confirmPassword: ""
                        });
                    }, onError(error) {
                        error?.message ? alert(error.message) : alert("Contact system admin.");
                    }
                });
            } catch (error) {
                console.log(error);
                error?.message ? alert(error.message) : alert("Contact system admin.");
            }
        } else {
            alert("Your password mis match on confirm")
        }
    }

    return (
        <>
            <form>
                <div className="border-2 border-solid p-4 rounded-2xl flex flex-col gap-5">
                    <div className="flex flex-col gap-1">
                        <label className="font-interSemiBold" htmlFor="currentPassword">
                            Current Password:
                        </label>
                        <input
                            className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                            type="password"
                            id="currentPassword"
                            name="currentPassword"
                            onChange={handleChange}
                            required />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className="font-interSemiBold" htmlFor="newPassword">
                            New Password:
                        </label>
                        <input
                            className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className="font-interSemiBold" htmlFor="confirmPassword">
                            Confirm New Password:
                        </label>
                        <input
                            className="h-8 w-full bg-evstarLandingInputField rounded-lg"
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="flex justify-center">
                        <div className="w-max">
                            <OutLinedButton text={'Update Password'} onClick={handleSubmit} />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
export default ChangePasswordContent;