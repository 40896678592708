import { ReactComponent as RightArrowIcon } from "../../assets/icons/longRightArrow.icon.svg";
import { useLazyQuery } from "@apollo/client";
import { GET_SHIPPING_LABEL_QUERY } from "../../api/queries/shipping-label.queries";
import { useState } from "react";

const ViewPrintLabelButton = ({ _id = "123" }) => {
  const [fetchShippingLabel] = useLazyQuery(GET_SHIPPING_LABEL_QUERY);
  const handleClick = async () => {
    try {
      const res = await fetchShippingLabel({
        variables: { _id },
      });
      if (!res?.data?.shippingLabel)
        throw new Error("Failed to fetch shipping label.");
      const shippingLabel = res.data.shippingLabel;
      // Convert base64 to raw binary data held in a string
      if (!shippingLabel.encoded_label)
        throw new Error("Failed to convert label.");
      const byteCharacters = atob(shippingLabel.encoded_label);

      // Convert raw binary string to an array of integers
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      // Convert array of integers into a blob
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `shipping-label-${shippingLabel.tracking_number}.pdf`;
      link.click();
    } catch (error) {
      error?.message ? alert(error.message) : alert(error);
    }
  };
  return (
    <button
      className="items-center font-interMedium text-sm gap-1 flex flex-row rounded-xtraLarge px-4 py-2 text-codiBlue bg-cyan-100"
      onClick={handleClick}
    >
      View/Print Label
      <RightArrowIcon className="fill-codiBlue" />
    </button>
  );
};

export default ViewPrintLabelButton;
