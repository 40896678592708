import Table from "./table.component";
import TableMessage from "./tableMessage.component";

import ContactEditLink from "../buttons/contactEdit.button.component";
import {UserContext} from "../../contexts/user.context"
import {useContext} from "react";



const CONTACTS_HEADERS = [
  {
    header: "Email",
    dataField: "email",
    style: "font-interSemiBold text-codiDarkGray text-base",
  },
  {
    header: "Name",
    dataField: "name",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  {
    header: "City",
    dataField: "city",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  {
    header: "Role",
    dataField: "role",
    style: "font-interMedium text-codiDarkGray text-base",
  },
  {
    header: "Actions",
    dataField: null,
    style: "",
  },
];

const ContactsTable = ({ contacts = [], customerId }) => {


  const {user} = useContext(UserContext);

  if (contacts?.length === 0) {
    return <TableMessage>No User Found</TableMessage>;
  }



  const capitalizeFirstLetter = (string) =>  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Table headers={CONTACTS_HEADERS}>
      {contacts &&
        contacts.map((contact, index1) => (
          <tr key={`${contact?._id}-${index1}`} className="h-16">
            {CONTACTS_HEADERS.map(({ dataField, style }, index2) => {
              if (index2 === CONTACTS_HEADERS?.length - 1) {
                return (
                  <td
                    key={`${contact?._id}-${index1}-${index2}`}
                    className="flex items-center justify-center h-16"
                  >

                    { user?.id !== contact?.id ? <ContactEditLink id={contact?._id} customerId={customerId} /> : '' }


                  </td>
                );
              }
              return (
                <td
                  key={`${contact?._id}-${index1}-${index2}`}
                  className={`${style} text-center cell-breakWord `}
                >

                  { dataField==="email" ?  contact?.[dataField] : capitalizeFirstLetter(contact?.[dataField])}
                </td>
              );
            })}
          </tr>
        ))}
    </Table>
  );
};

export default ContactsTable;
