import CardTemplate from "./template/card.template.component";
import TimelinePoint from "../timeline/point.timeline";

const ClaimTimelineCard = ({ fillPercent = 0 }) => {
  return (
    <CardTemplate>
      <div className="relative min-h-[90px] px-[5%]">
        <div className="m-auto px-[6%] w-[86%]">
          <div className="flex top-10 relative w-full h-[16px] bg-lightGray rounded-[6px] overflow-visible">
            <div
              style={{
                width: `${fillPercent}%`,
              }}
              className={`absolute h-full progress-bar bg-cyan-100 p-1 rounded-md border border-white border-opacity-30`}
            ></div>
          </div>
        </div>
        <div className="flex flex-row">
          <TimelinePoint checkmark={fillPercent > 0} position={1}>
            <p>Ticket</p>
            <p>Created</p>
          </TimelinePoint>
          <TimelinePoint checkmark={fillPercent >= 33} position={2}>
            <p>Devices(s)</p>
            <p>Received</p>
          </TimelinePoint>
          <TimelinePoint checkmark={fillPercent >= 66} position={3}>
            <p>Repairs</p>
            <p>Performed</p>
          </TimelinePoint>
          <TimelinePoint checkmark={fillPercent >= 100} position={4}>
            <p>Return</p>
            <p>Shipment</p>
          </TimelinePoint>
        </div>
      </div>
    </CardTemplate>
  );
};

export default ClaimTimelineCard;
