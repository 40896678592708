
const OutLinedButton = (props) => {
    const { text, onClick } = props;
    return (
        <>
            <div className="cursor-pointer flex flex-col items-center px-6 py-2.5 border-2 border-solid border-evstarLighGreen hover:border-evstarLighGreen-700/100 rounded-lg w-full text-evstarLighGreen text-sm hover:bg-evstarLighGreen hover:text-white transition duration-300 ease-in-out transform"
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}>
                {text}
            </div>
        </>
    );
}
export default OutLinedButton;