import SimpleCardTemplate from "./template/simpleCard.template.component";
import { ReactComponent as DevicesIcon } from "../../assets/icons/devices.icon.svg";

const CoveredDevicesCard = ({ coveredDevices = 0 }) => {
  return (
    <SimpleCardTemplate
      style="bg-codiDarkRed"
      label="Covered Devices"
      value={coveredDevices}
    >
      <DevicesIcon className="fill-white" />
    </SimpleCardTemplate>
  );
};

export default CoveredDevicesCard;
