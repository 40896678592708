import { gql } from "@apollo/client";

export const GET_ASSETS_COUNT_QUERY = gql`
  query GetAssetCount($searchParams: String, $AssetInput: AssetInput) {
    getAssetCount(searchParams: $searchParams, AssetInput: $AssetInput)
  }
`;

export const GET_ASSETS = gql`
  query Assets($searchParams: String, $AssetInput: AssetInput) {
    assets(searchParams: $searchParams, AssetInput: $AssetInput) {
      _id
      id
      customer_id
      contact_id
      name
      created_at
      updated_at
      asset_serial
      asset_type
      asset_type_id
      make
      model
      service_tag
      warranty
      warranty_type
      warranty_end_date
      warranty_start_date
      customer_business_name
      properties
    }
  }
`;

export const GET_ASSET = gql`
  query GetAsset($id: String!) {
    asset(id: $id) {
      _id
      id
      customer_id
      contact_id
      name
      created_at
      updated_at
      asset_serial
      asset_type
      asset_type_id
      make
      model
      service_tag
      warranty
      warranty_type
      warranty_end_date
      warranty_start_date
    }
  }
`;
