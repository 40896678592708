import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_COMMENT_MUTATION } from "../api/mutations/ticket.mutations";
import {
  GET_TICKET_QUERY,
  GET_TICKETS_QUERY,
  GET_TICKET_COUNT_QUERY,
} from "../api/queries/ticket.queries";
import { useContext } from "react";
import { UserContext } from "../contexts/user.context";

const useTicket = () => {
  const { user } = useContext(UserContext);
  const [addCommentMutation] = useMutation(ADD_COMMENT_MUTATION);
  const [ticketQuery] = useLazyQuery(GET_TICKET_QUERY, {
    fetchPolicy: "network-only",
  });
  const [ticketsQuery] = useLazyQuery(GET_TICKETS_QUERY, {
    fetchPolicy: "network-only",
  });
  const [ticketCountQuery] = useLazyQuery(GET_TICKET_COUNT_QUERY, {
    fetchPolicy: "network-only",
  });

  const addComment = async ({
    ticket_id,
    newStatus,
    body,
    subject,
    hidden,
    tech,
  }) => {
    console.log('Ticket ID:', ticket_id);
    console.log('Body:', body);
    console.log('Subject:', subject);
    console.log('Tech:', tech);
    console.log('newStatus:', newStatus);
    try {
      const response = await addCommentMutation({
        variables: {
          ticket_id,
          newStatus,
          body,
          subject,
          hidden,
          tech,
        },
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.addComment || {};
    } catch (error) {
      console.error(error?.message);
    }
  };

  const getTicketCount = async ({ TicketInput, searchParams }) => {
    try {

      let variables = {
        searchParams
      };

      if (user?.role !== "codi admin") {
        variables.TicketInput = { ...TicketInput, customer_id: user?.customer_id };
      } else {
        variables.TicketInput = { ...TicketInput }
      }
      const response = await ticketCountQuery({
        variables: variables
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.getTicketCount || 0;
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getTicket = async (ticketId) => {
    if (!ticketId) return;
    const response = await ticketQuery({ variables: { _id: ticketId } });
    return response?.data?.ticket || {};
  };
  const getTickets = async ({ searchParams, TicketInput }) => {
    try {
      //console.log({ TicketInput });
      let variables = {
        searchParams
      };

      if (user?.role !== "evstar admin") {
        variables.TicketInput = { ...TicketInput, customer_id: user?.customer_id };
      } else {
        variables.TicketInput = { ...TicketInput }
      }

      const response = await ticketsQuery({
        variables: variables/*{
          searchParams,
          TicketInput: { ...TicketInput, customer_id: user?.customer_id }//,
          //user_id: (user.role === "limited" ? user.id : null )
        },*/
      });
      if (response?.error) throw new Error(response.error);
      return (
        response?.data?.tickets.map((ticket) => {
          return {
            ...ticket,
            asset_serial: ticket?.assets?.[0]?.asset_serial,
          };
        }) || []
      );
    } catch (error) {
      console.error(error?.message);
    }
  };

  return { getTicket, getTickets, getTicketCount, addComment };
};

export default useTicket;
