import SortBySelectionInput from "./inputs/selections/sortBy.selection.input";
import SortDirectionSelectionInput from "./inputs/selections/sortDirection.selection.input";
import StatusFilterSelection from "./inputs/selections/statusFilter.selection.component";
import SearchInput from "./inputs/texts/search.input.component";

const INCLUDE_STATUS_FILTER = ["claims"];

const SearchOptions = ({
  type,
  searchOptionsState = [],
  handleSearchChange,
}) => {
  const [searchOptions, setSearchOptions] = searchOptionsState;

  return (
      window.screen.width <= 678 ? <div className="border-2 gap-2 border-codiTableHeaders flex-wrap flex flex-row p-2 rounded-large justify-evenly  search-options ">
              <div className='flex flex-col'>
              <SearchInput
                  searchOptionsState={searchOptionsState}
                  value={searchOptions?.search || ""}
              />
              <SortBySelectionInput options={type} />
              <SortDirectionSelectionInput />
              {INCLUDE_STATUS_FILTER?.includes(type) && <StatusFilterSelection />}
              </div>
          </div> :
    <div className="border-2 gap-2 border-codiTableHeaders flex-wrap flex flex-row p-2 rounded-large justify-evenly search-options">
      <SearchInput
        searchOptionsState={searchOptionsState}
        value={searchOptions?.search || ""}
      />
      <SortBySelectionInput options={type} />
      <SortDirectionSelectionInput entity={type} />
      {INCLUDE_STATUS_FILTER?.includes(type) && <StatusFilterSelection />}
    </div>
  );
};

export default SearchOptions;
