import { useContext, useEffect, useState } from "react";
import ClaimsTable from "../tables/claims.table.component";
import PaginationBar from "../paginationBar.component";
import SearchOptions from "../searchOptions.component";
import CardTemplate from "./template/card.template.component";
import { useSearchParams, useLocation } from "react-router-dom";
import useTicket from "../../hooks/useTicket";
import { UserContext } from "../../contexts/user.context";

const DEFAULT_SEARCH_OPTIONS = {
  search: "",
  sortBy: "number",
  sortDirection: "asc",
  statusFilter: [],
  page: 1,
  maxPages: 10,
};

const ClaimsTableCard = ({
  title = "",
  TicketInput,
  totalClaimsState,
  children,
}) => {
  const [totalClaims, setTotalClaims] = totalClaimsState || [0, () => null];
  const { user } = useContext(UserContext);
  const { getTickets, getTicketCount } = useTicket();
  const [searchParams] = useSearchParams();
  const [tickets, setTickets] = useState([]);
  const [ticketCount, setTicketCount] = useState(0);
  const [searchOptions, setSearchOptions] = useState(DEFAULT_SEARCH_OPTIONS);
  const { search, sortBy, sortDirection, statusFilter, maxPages } =
    searchOptions;
  const [page, setPage] = useState(+searchParams?.get("page") || 1);

  useEffect(() => {
    if (!user?.id) return;
    if (!searchParams.toString()) return;
    const fetchAndSetTickets = async () => {
      if (searchOptions.search !== "") {
        searchParams.append("page", "1");
      }
      const fetchedTickets = await getTickets({
        searchParams: searchParams.toString(),
        TicketInput: { ...TicketInput },
      });
      setTickets(fetchedTickets);
      setTotalClaims(fetchedTickets?.length);
    };
    fetchAndSetTickets();
    const fetchTicketCount = async () => {
      const fetchedTicketCount = await getTicketCount({
        searchParams: searchParams.toString(),
        TicketInput: { ...TicketInput },
      });
      setTicketCount(fetchedTicketCount);
    };
    fetchTicketCount();
  }, [searchParams, user, TicketInput]);

  return (
    <CardTemplate title={title || children}>
      <SearchOptions
        type="claims"
        searchOptionsState={[searchOptions, setSearchOptions]}
      />
      <ClaimsTable claims={tickets} />
      <PaginationBar currentPage={page} maxPages={Math.ceil(ticketCount / 25)} />
    </CardTemplate>
  );
};

export default ClaimsTableCard;
