import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";


const SearchInput = ({ searchOptionsState, ...otherProps }) => {
  const [searchOptions, setSearchOptions] = searchOptionsState;
  const { search } = searchOptionsState?.length ? searchOptions : "";
  const [timedSearch, setTimedSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearch = searchParams.get("search") || "";
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimedSearch(search);
    }, 700);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);
  useEffect(() => {
    if (currentSearch === timedSearch) return;
    if (timedSearch === "") {
      searchParams.delete("search");
    } else {
      searchParams.set("search", timedSearch);
    }
    setSearchParams(searchParams);
  }, [timedSearch]);

  const handleChange = (e) => {
    //console.log(searchOptions);
    //console.log(e.target.name);
    setSearchOptions({ ...searchOptions, [e.target.name]: e.target.value });
  };

  return (

    <div className="flex relative flex-1 flex-col gap-1 ">
      <label className="font-inter text-base">Search</label>
      <input
        type="search"
        placeholder={`${currentSearch ? currentSearch : "Search..."}`}
        autoComplete="off"
        name="search"
        value={search}
        onChange={handleChange}
        {...otherProps}
        className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
      />
    </div>
  );
};

export default SearchInput;
