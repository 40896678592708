const titleStyle = {
  default: "rounded-xtraLarge p-4 bg-white w-full",
  withTitle: "rounded-xtraLarge bg-white w-full",
};

const CardTemplate = ({ title = "", children }) => {
  return (
    <div
      className={title ? `${titleStyle.withTitle}` : `${titleStyle.default}`}
    >
      {title && (
        <div className="py-2 text-lg font-interSemiBold uppercase text-white bg-codiTableHeaders rounded-t-xtraLarge flex justify-center">
          {title}
        </div>
      )}
      <div className="bg-white p-1 rounded-b-xtraLarge flex flex-col gap-1">
        {children}
      </div>
    </div>
  );
};

export default CardTemplate;
