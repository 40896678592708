import DefaultLayout from "../layouts/default.layout";
import DashboardDetails from "../components/dashboardDetails.component";
import ClaimsTableCard from "../components/cards/claimsTable.card";
import LoggedIn from "../layouts/loggedIn.layout";
const Dashboard = () => {
  return (
    <LoggedIn>
      <DefaultLayout title="Dashboard">
        <DashboardDetails />
        <ClaimsTableCard title="Recent Claims" />
      </DefaultLayout>
    </LoggedIn>
  );
};

export default Dashboard;
