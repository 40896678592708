import { useNavigate } from "react-router-dom";
import Heading from "../shared/heading.landing";
import { Spinner } from "@material-tailwind/react";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";

const ViewClaimsContent = ({ claims = [], isLoading }) => {
    const navigate = useNavigate();
    const cellClass = `w-[35%] sm:w-[20%] px-3`;

    const handleClick = (_id) => {
        navigate(`/view-claims/${_id}`);
    }

    const { user } = useContext(UserContext);



    return (
        <>
            <Heading text={'Open Claims'} />
            <div className=" flex flex-col gap-3">
                <div className="flex flex-col border-2 border-solid p-4 rounded-2xl">
                    <div className="font-bold text-lg">Search</div>
                    <input type="text" className="h-8 w-full md:w-1/2 bg-evstarLandingInputField rounded-lg" />
                </div>
                {isLoading ?
                    <div className="flex flex-row gap-4">
                        <div>Fetching Claims...</div>
                        <Spinner color="light-green" className="h-8 w-8" />
                    </div>
                    :
                    <div className="w-full overflow-x-auto">
                        <div className="flex flex-row font-bold ">
                            <div className={`w-[25%] sm:w-[15%] px-3`}>Claim #</div>
                            <div className={`${cellClass}`}>Customer</div>
                            <div className={`${cellClass}`}>Serial Number</div>
                            <div className={`${cellClass}`}>Status</div>
                            <div className={`${cellClass}`}>Create Date</div>
                        </div>
                        <div><hr className={`border-[1px] border-black rounded-3xl w-[115%] sm:w-full`} /></div>
                        <div >
                            {
                                claims.map((claim, i) => {
                                    if(user && user.role==='admin')
                                        {
                                            if(user.customer_id===claim.customer_id)
                                                {
                                                    return (
                                                        <>
                                                            <div key={`$${claim?._id}`} className={`cursor-pointer hover:bg-gray-100 flex flex-row font-bold text-gray-600   py-4`}
                                                                onClick={() => {
                                                                    handleClick(claim._id);
                                                                }}
                                                            >
                                                                <div className={`w-[25%] sm:w-[15%] px-3`}>{claim['number_string']}</div>
                                                                <div className={`${cellClass} `}>{claim['customer_business_then_name']}</div>
                                                                <div className={`${cellClass} overflow-clip`}>{claim['asset_serial']}</div>
                                                                <div className={`${cellClass} `}>{claim['status']}</div>
                                                                <div className={`${cellClass} `}>{claim['created_at']}</div>
                                                            </div>
                                                            <div>
                                                                <hr className={`border-[1px] border-gray-200 rounded-3xl w-[115%] sm:w-full`} />
                                                            </div>
                                                        </>
                                                    )
                                                }
                                        }
                                        else{
                                            return (
                                                <>
                                                    <div key={`$${claim?._id}-${i}`} className={`cursor-pointer hover:bg-gray-100 flex flex-row font-bold text-gray-600   py-4`}
                                                        onClick={() => {
                                                            handleClick(claim._id);
                                                        }}
                                                    >
                                                        <div className={`w-[25%] sm:w-[15%] px-3`}>{claim['number_string']}</div>
                                                        <div className={`${cellClass} `}>{claim['customer_business_then_name']}</div>
                                                        <div className={`${cellClass} overflow-clip`}>{claim['asset_serial']}</div>
                                                        <div className={`${cellClass} `}>{claim['status']}</div>
                                                        <div className={`${cellClass} `}>{claim['created_at']}</div>
                                                    </div>
                                                    <div>
                                                        <hr className={`border-[1px] border-gray-200 rounded-3xl w-[115%] sm:w-full`} />
                                                    </div>
                                                </>
                                            )
                                        }
                                    
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
export default ViewClaimsContent;