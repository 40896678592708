import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

const DEFAULT_FILTER = [
  // "Resolved",
  // "Resolved - BER",
  // "Resolved - Buyout",
  // "Resolved - Canceled",
  // "Resolved - Denied",
  // "Resolved- Shipped",
  // "Resolved - Unable To Repair",
];
const STATUS_OPTIONS = [
  "Additional Time Needed",
  "BER Approved",
  "BER Recommended",
  "Buyout Requested",
  "Contact Customer",
  "Customer Reply",
  "Delayed - Pricing Requested",
  "Delayed - Repair Approved",
  "Delayed - Repair Request",
  "Denied - Return to Customer",
  "In Progress",
  "IW - Waiting on Part",
  "New",
  "OW - Waiting on Part",
  "Part(s) Arrived",
  "Parts on Backorder",
  "Pending Courier Pick-up/Delivery",
  "Pending QA",
  "Pending QA - No Repair Needed",
  "Pending Reassembly",
  "QA Review",
  "Ready for Invoicing",
  "Ready for Repair",
  "Ready for shipment",
  "Received",
  "Repair Complete",
  "Resolved",
  "Resolved - BER",
  "Resolved - Buyout",
  "Resolved - Canceled",
  "Resolved - Denied",
  "Resolved- Shipped",
  "Resolved - Unable To Repair",
  "Scheduled",
  "Shipping Label Sent",
  "Solder Queue",
  "Uploaded to Shipstore",
  "Waiting for Parts",
  "Waiting on BER Credit",
  "Waiting on Customer",
];

const StatusFilterSelection = ({ options = () => null, ...otherProps }) => {
  const [selectedOptions, setSelectedOptions] = useState(DEFAULT_FILTER);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dropdown, setDropdown] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    options(selectedOptions);
    searchParams.set("statusFilter", selectedOptions.join(","));
    setSearchParams(searchParams);
  }, [selectedOptions]);

  const handleOptionsSelect = (event) => {
    const value = event.target.value;
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleClick = () => {
    setDropdown(!dropdown);
  };

  return (
    <div className="flex relative flex-col gap-1 flex-1" ref={menuRef}>
      <label className="font-inter text-base">Status Filter</label>
      <div
        className="cursor-pointer bg-lightGray rounded-lg p-2 w-full text-base font-interMedium "
        onClick={handleClick}
      >
        {selectedOptions.length} Selected
      </div>

      <div
        className={`p-4 shadow-md max-h-[200px] overflow-y-scroll rounded-lg bg-lightGray flow-root absolute left-0 top-[75px] ${
          !dropdown ? "hidden" : ""
        }`}
        ref={menuRef}
      >
        {STATUS_OPTIONS.map((option) => (
          <div
            key={option}
            className="flex rounded-lg px-2 hover:bg-white items-center mt-1"
          >
            <input
              type="checkbox"
              id={option}
              name={option}
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={(e) => {
                handleOptionsSelect(e);
              }}
              className="mr-2"
            />
            <label htmlFor={option} className="text-sm">
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusFilterSelection;
