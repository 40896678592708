const Heading = (props)=>{
    const {text}=props;
    return (
        <>
            <div className="border-2 border-solid text-xl md:text-3xl p-4 rounded-2xl font-bold ">
                {text}
            </div>
        </>
    );
}

export default Heading;