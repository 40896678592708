import DefaultLayout from "../layouts/default.layout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TEST_ASSET from "../constants/test/asset.test.constant";
import ClaimDetailsCard from "../components/cards/claimDetails.card";
import AssociatedDevicesCard from "../components/cards/associatedDevices.card";
import FeedbackCommentCard from "../components/cards/feedbackComment.card";
import ClaimTimelineCard from "../components/cards/claimTimeline.card";
import LoggedIn from "../layouts/loggedIn.layout";
import { useLazyQuery } from "@apollo/client";
import { GET_TICKET_QUERY } from "../api/queries/ticket.queries";

const Claim = () => {
  const { claimId } = useParams();
  const [ticket, setTicket] = useState({});
  const [asset, setAsset] = useState(TEST_ASSET);
  const [fillPercent, setFillPercent] = useState(1);
  const { _id, number, comments } = ticket;
  const [fetchTicket, { error, loading, data }] = useLazyQuery(
    GET_TICKET_QUERY,
    {
      variables: {
        _id,
      },
      onCompleted: (data) => {
        setTicket(data.ticket);
        setAsset(data.ticket.assets[0]);
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (ticket?.status_changes?.length) {
      const statusChanges = ticket.status_changes.map((statusChange) => {
        return statusChange.new_status;
      });
      const resolved = statusChanges.find((status) =>
        status.includes("Resolved")
      );
      if (resolved) return setFillPercent(100);
      const repaired = statusChanges.find((status) =>
        status.includes("Ready for shipment")
      );
      if (repaired) return setFillPercent(67);
      const received = statusChanges.find((status) =>
        status.includes("Received")
      );
      if (received) return setFillPercent(34);
    }
  }, [ticket]);

  useEffect(() => {
    const _id = claimId;
    fetchTicket({ variables: { _id } });
  }, []);

  useEffect(() => {
    fetchTicket({ variables: { _id: claimId } });
  }, [claimId]);

  return (
    <LoggedIn>
      <DefaultLayout title={`Claim # ${ticket?.number}`}>
        <ClaimTimelineCard fillPercent={fillPercent} />
        <ClaimDetailsCard ticket={ticket} asset={asset} />
        <AssociatedDevicesCard assets={[asset]} />
        <FeedbackCommentCard ticket_id={ticket?.id} comments={comments} />
      </DefaultLayout>
    </LoggedIn>
  );
};

export default Claim;
