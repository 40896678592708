import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./contexts/user.context";
import { CustomerProvider } from "./contexts/customer.context";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { concat } from "@apollo/client/core";
const ENVIRONMENT_MAP = {
  development: {
    token: "REACT_APP_DEVELOPMENT_TOKEN",
    uri: "http://localhost:8080/graphql",
  },
  production: {
    token: "REACT_APP_TOKEN",
    uri: "https://account.api.evstar.com/graphql",
    // uri: "https://account.test.api.evstar.com/graphql",
  },
  test: {
    token: "REACT_APP_TOKEN",
    uri: "https://account.test.api.evstar.com/graphql",
  },
};
const environment = ENVIRONMENT_MAP[process.env.REACT_APP_ENVIRONMENT];
const token = process.env[environment.token];
const uri = environment.uri;

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
      token: localStorage.getItem("token"),
    },
  };
});
const httpLink = new HttpLink({
  uri: uri,
  credentials: "include",
});
const client = new ApolloClient({
  link: concat(authLink, httpLink),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <UserProvider>
        <CustomerProvider>
          <App />
        </CustomerProvider>
      </UserProvider>
    </BrowserRouter>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
