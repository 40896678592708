import Footer1 from "../components/footer.component";
import Header1 from "../components/header.component1";
import HomeContent from "../components/content/home.content";

const Home = () => {
    return (
        <>
            <div className="bg-white flex flex-col items-center min-h-screen">
                <div className="md:w-5/6 w-full">
                    <Header1 showMyAccount />
                    <HomeContent />
                    <Footer1 />
                </div>
            </div>
        </>
    );
}
export default Home;