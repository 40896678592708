import React, {useContext, useState, useEffect} from "react";
import {userContext} from "../../contexts/user.context"
import {useLazyQuery, useMutation} from "@apollo/client";
import {UPDATE_CUSTOMER_MUTATION, DELETE_CUSTOMER} from "../../api/mutations/customer.mutations"
import {UserContext} from "../../contexts/user.context";
import {GET_CUSTOMER_QUERY} from "../../api/queries/customer.queries";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from "react-router-dom";
import {onError} from "@apollo/client/link/error";

const states = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming']

const CUSTOMER = {
    email: "", firstname: "", lastname: "", address: "", phone:"", city:"", state:"", zip:"", business_name: ""
}
const CustomerSaveForm = (customerId ) => {
    const [CustomerForm, setCustomerForm] = useState({});
    //console.log(customer);
    //setCustomerForm(customer);
    const [customer, setCustomer] = useState({});

    const navigate = useNavigate();
    const customer_id = customer.id;
    let id = customerId;

    if(typeof  id === "object"){
        id = id.customerId;
    }

    id = parseInt(id);

    const [Customer, { error, loading, data }] = useLazyQuery(GET_CUSTOMER_QUERY,   {
        variables: {
            id: parseInt(id)
        },
        onCompleted: (data) => {
            //console.log(data.getCustomer);
            setCustomer(data.customer);
        },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
    // get asset from API
    let id = customerId;
        if(typeof  id == "object"){
            id = id.customerId;
        }
        id= parseInt(id)
    Customer({ variables: { id } });
      }, [customerId]);
    const {email, firstname, lastname, /*password, */address, phone, city, state, zip, business_name} = customer;

    const handleChange = (e) => {
        setCustomer({...customer, [e.target.name]: e.target.value});
    };

    //console.log(CustomerForm);

    const user = useContext(UserContext).user;

    const [UpdateCustomer] = useMutation(UPDATE_CUSTOMER_MUTATION);


    const handleSubmit = () => {

        try{
            if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)){
                alert("Your email is incorrect!");
                return false;
            }



            if(address === ""){
                alert("Address is required!");
                return false;
            }

            if(business_name === ""){
                alert("Business Name is required!");
                return false;
            }

            if(city === ""){
                alert("City is required!");
                return false;
            }

            if(state === ""){
                alert("State is required!");
                return false;
            }

            if(zip === ""){
                alert("Zip is required!");
                return false;
            }

            if(firstname === ""){
                alert("First Name is required!");
                return false;
            }

            if(lastname === ""){
                alert("Last Name is required!");
                return false;
            }
            const CustomerInput = {
                email: email,
                firstname: firstname,
                lastname: lastname,
                address: address,
                phone: phone,
                city: city,
                state: state,
                zip: zip
            }

            UpdateCustomer({
                variables: {
                    id: id,
                    email: email,
                    firstname: firstname,
                    lastname: lastname,
                    business_name: business_name,
                    address: address,
                    phone: phone,
                    city: city,
                    state: state,
                    zip: zip
                }, onCompleted: (res) => {
                    alert(`Customer updated. Customer #${res.updateCustomer.id}`);
                    console.log(res);
                },
                onError: (e) => {
                    alert(e.message);
                }
        });
        }
        catch (error){
            alert(error?.message);
        }

    }

    console.log(email, firstname, lastname, address, city, state, zip, phone, business_name);

    return (
        <form className=" gap-4 customer-form">

            <div className="flex flex-col half-width left">
                <label className="" htmlFor="firstname">
                    First Name *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="firstname"
                    id="firstname"
                    onChange={handleChange}
                    value={firstname}
                    required
                />
            </div>

            <div className="flex flex-col half-width">
                <label className="" htmlFor="lastname">
                    Last Name *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="lastname"
                    id="lastname"
                    onChange={handleChange}
                    value={lastname}
                    required
                />
            </div>

            <div className="flex flex-col">
                <label className="" htmlFor="email">
                    Email *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    required
                />
            </div>

            <div className="flex flex-col">
                <label className="" htmlFor="business_name">
                    Business Name *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="email"
                    id="business_name"
                    name="business_name"
                    onChange={handleChange}
                    value={business_name}
                    required
                />
            </div>

            <div className="flex flex-col">
                <label className="" htmlFor="address">
                    Address *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="address"
                    id="address"
                    onChange={handleChange}
                    value={address}
                    required
                />
            </div>
            {/*<div className="flex flex-col">
                <label className="font-interSemiBold" htmlFor="address2">
                    Address 2:
                </label>
                <input
                    className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="address2"
                    id="address2"
                    value={address2}
                    onChange={handleChange}
                    required
                />
            </div>*/}
            <div>
            <div className="flex flex-col half-width left" >
                <label className="" htmlFor="city">
                    City *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="city"
                    id="city"
                    onChange={handleChange}
                    required
                    value={city}
                />
            </div>
            <div className="flex flex-col half-width">
                <label className="" htmlFor="state">
                    State *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type='text'
                    name="state"
                    id="state"
                    onChange={handleChange}
                    required
                    value={state}

                />
            </div>
            </div><div>
            <div className="flex flex-col half-width left">
                <label className="" htmlFor="zip">
                    ZIP *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="zip"
                    id="zip"
                    value={zip}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="flex flex-col  half-width">
                <label className="" htmlFor="phone">
                    Phone
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={handleChange}
                    required
                />
            </div>

            </div>



            <div className="flex flex-row justify-evenly">

                <button
                        className=" text-center  bg-black items-center   px-12 py-2  rounded-3xl"
                        style={{color: "white", marginRight: '3em'}} type={"button"} onClick={handleSubmit}>Update Customer
                </button>




            </div>
        </form>
    );
};

export default CustomerSaveForm;
