import { gql } from "@apollo/client";

export const CREATE_TICKET_MUTATION = gql`
  mutation CreateTicket(
    $subject: String
    $problem_type: String!
    $comment: String!
    $asset_id: Int
    $contact_id: Int
    $customer_id: Int
    $asset_serial: String
    $make: String
    $model: String
    $tracking_number: String
    $asset_type: String
    $data: JSON
  ) {
    createTicket(
      subject: $subject
      problem_type: $problem_type
      comment: $comment
      asset_id: $asset_id
      contact_id: $contact_id
      customer_id: $customer_id
      asset_serial: $asset_serial
      make: $make
      model: $model
      tracking_number: $tracking_number
      asset_type: $asset_type
      data:$data
    ) {
      id
      number
    }
  }
`;

export const ADD_COMMENT_MUTATION = gql`
  mutation AddComment(
    $ticket_id: Int!
    $newStatus: String!
    $body: String!
    $subject: String!
    $hidden: Boolean
    $tech: String
  ) {
    addComment(
      ticket_id: $ticket_id
      newStatus: $newStatus
      body: $body
      subject: $subject
      hidden: $hidden
      tech: $tech
    ) {
      id
      subject
      body
      updated_at
    }
  }
`;
