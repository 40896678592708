import { gql } from "@apollo/client";

export const VERIFY_CONTACT_QUERY = gql`
  query VerifyContact($token: String!) {
    verifyContact(token: $token) {
      _id
      name
      email
      address1
      address2
      city
      state
      zip
      id
      customer_id
      require_password_change
      role
    }
  }
`;

export const CONTACTS_QUERY = gql`
  query Contacts($customer_id: Int!, $sort: String) {
    contacts(customer_id: $customer_id, sort: $sort) {
      _id
      name
      email
      address1
      address2
      city
      state
      zip
      id
      customer_id
      require_password_change
      role
    }
  }
`;

export const FETCH_CONTACTS = gql`
  query fetchContacts($customer_id: Int!, $searchParams: String, $sort: String, $sortDirection: String) {
    fetchContacts(customer_id: $customer_id, searchParams: $searchParams, sort: $sort, sortDirection: $sortDirection) {
      _id
      name
      email
      address1
      address2
      city
      state
      zip
      id
      customer_id
      require_password_change
      role
      phone
    }
  }
`;



export const CONTACT_QUERY = gql`
  query Contact($customer_id: Int!, $_id: String!) {
    getContact(customer_id: $customer_id, _id: $_id) {
      _id
      name
      email
      address1
      address2
      city
      state
      zip
      id
      customer_id
      require_password_change
      role
      phone
    }
  }
`;

export const FETCH_CONTACTS_COUNT_QUERY = gql`
  query FetchContactsCount($customer_id: Int, $searchParams: String) {
    fetchContactsCount(customer_id: $customer_id, searchParams: $searchParams)
  }
`;
