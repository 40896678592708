import { useState } from "react";
import Footer1 from "../components/footer.component";
import Header1 from "../components/header.component1";
import MenuBarMobile from "../components/sidebar/menubar.mobile";
import Sidebar from "../components/sidebar/sidebar.component";
import CustomerInfo from "../components/content/customer.accountInfo.content";
import { useLocation } from "react-router-dom";
import Heading from "../components/shared/heading.landing";
import ChangePasswordContent from "../components/content/password.account.content";

const Account = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const location = useLocation();
    const path = location.pathname;

    return (
        <>
            <div className="bg-white flex flex-col items-center min-h-screen">
                <div className="lg:w-5/6 w-full">
                    <Header1 showWhatsCovered />
                        <div className="h-full ">
                            <div className="flex f-full ">
                                <MenuBarMobile setter={setShowSidebar} />
                                <Sidebar show={showSidebar} setter={setShowSidebar} />
                                <div className="flex flex-col flex-grow w-full md:w-full min-h-screen p-4 gap-4">
                                    <Heading text={path.includes('password') ? `Change Password` : `Account information`} />
                                    {path.includes('password') ? <ChangePasswordContent /> : <CustomerInfo />}
                                </div>
                            </div>
                        </div>
                    <Footer1 />
                </div>
            </div>
        </>
    );
}
export default Account;