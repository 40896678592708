import { useEffect, useState } from "react";
import DefaultLayout from "../layouts/default.layout";
import { useParams } from "react-router-dom";
import ClaimsTableCard from "../components/cards/claimsTable.card";
import DeviceDetailsCard from "../components/cards/deviceDetails.card";
import WarrantyDetailsCard from "../components/cards/warrantyDetails.card";
import LoggedIn from "../layouts/loggedIn.layout";
import { useLazyQuery } from "@apollo/client";
import { GET_ASSET } from "../api/queries/asset.queries";

const defaultAsset = {
  asset_serial: "asset_serial",
  make: "make",
  model: "model",
  asset_tag: "asset_tag",
  warranty: false,
  warranty_type: "",
  warrantyStartDate: "",
  warrantyEndDate: "",
};

const Asset = () => {
  const { assetId } = useParams();
  const [asset, setAsset] = useState([]);
  const [totalClaims, setTotalClaims] = useState(0);
  const [fetchAsset, { error, loading, data }] = useLazyQuery(GET_ASSET, {
    variables: {
      assetId,
    },
    onCompleted: (data) => {
      setAsset(data.asset);
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    // get asset from API
    const id = assetId;
    fetchAsset({ variables: { id } });
  }, [assetId]);
  return (
    <LoggedIn>
      <DefaultLayout title={`Asset ${asset?.asset_serial}`}>
        <div className="flex flex-row w-full gap-4 container-header">
          <DeviceDetailsCard asset={asset} />
          <WarrantyDetailsCard asset={asset} />
        </div>
        <ClaimsTableCard
          TicketInput={{ asset_serial: asset?.asset_serial }}
          totalClaimsState={[totalClaims, setTotalClaims]}
        >
          <>
            <p className="px-2">Associated Claims</p>
            <span className="font-interMedium text-base">
              ({totalClaims} Total)
            </span>
          </>
        </ClaimsTableCard>
      </DefaultLayout>
    </LoggedIn>
  );
};

export default Asset;
