import { gql } from "@apollo/client";

export const PASSWORD_RESET_MUTATION = gql`
  mutation PasswordReset($password: String!, $token: String!, $currentPassword: String, $email: String) {
    passwordReset(password: $password, token: $token, current_password: $currentPassword, email: $email)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!) {
    emailPasswordReset(email: $email)
  }
`;

export const DELETE_CONTACT = gql`
  mutation deleteContact($_id: String!) {
    deleteContact(_id: $_id)
  }
`;

export const LOGOUT_CONTACT_MUTATION = gql`
  mutation LogoutContact {
    logoutContact
  }
`;

export const LOGIN_CONTACT_MUTATION = gql`
  mutation LoginContact($email: String!, $password: String!) {
    loginContact(email: $email, password: $password) {
      contact {
        _id
        name
        email
        address1
        address2
        city
        state
        zip
        id
        customer_id
        require_password_change
        role
      }
      token
    }
  }
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateContact(
    $_id: String!
    $email: String
    $password: String
    $name: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $role: String
    $phone: String
  ) {
    updateContact(
      _id: $_id
      email: $email
      password: $password
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      role: $role,
      phone: $phone
    ) {
      _id
      name
      email
      address1
      address2
      city
      state
      zip
      id
      customer_id
      require_password_change
      role
    }
  }
`;

export const CREATE_CONTACT_MUTATION = gql`
  mutation CreateContact(
    $email: String!
    $password: String
    $name: String!
    $address1: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
    $role: String!
    $customer_id: Int!
  ) {
    createContact(
      email: $email
      password: $password
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      role: $role
      customer_id: $customer_id
    ) {
      name
      email
      address1
      address2
      city
      state
      zip
      id
      customer_id
      require_password_change
      role
    }
  }
`;
