function formatDate(date) {
  if (!date) return "";
  const dateObj = new Date(+date);
  let day = dateObj.getDate().toString();
  let month = (dateObj.getMonth() + 1).toString(); // getMonth() is zero-indexed
  let year = dateObj.getFullYear().toString();

  // Ensure day and month are two digits by padding with a leading zero if necessary
  day = day.padStart(2, "0");
  month = month.padStart(2, "0");

  return `${month}-${day}-${year}`;
}

export default formatDate;
