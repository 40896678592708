import Login from "../layouts/login.layout";
import DefaultLayout from "../layouts/default.layout";
import SupportForm from "../components/forms/support.form";
import CardTemplate from "../components/cards/template/card.template.component";

const Support = () => {
  return (
    <Login>
      <DefaultLayout title="Support">
        <CardTemplate>
          <SupportForm />
        </CardTemplate>
      </DefaultLayout>
    </Login>
  );
};

export default Support;
